import { Injectable } from '@angular/core';
import { UrlUtil } from '../utils/url.util';
import {
  FolioDocument,
  FolioDocumentRequest,
  FolioPageResult,
  FolioPublicDocumentLinksPage,
  FolioPublicDocumentLinkRequest,
  FolioPublicDocumentLinkResponse,
  SearchSuggestions,
  FolioDocumentMetrics
} from '../models';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { ErrorService } from './error.service';
import { AuditService } from './audit.service';
import { FolioState } from '../../ngrx/store';
import { Store } from '@ngrx/store';
import { FolioActions } from '../../ngrx/actions';
import { selectIndexedDocuments } from '../../ngrx/selectors';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private store: Store<FolioState>,
              private http: HttpClient,
              private auditService: AuditService,
              private errorService: ErrorService) {
  }

  /**
   * Get indexed folio document
   */
  getIndexedFolioDocument(docId: string, ignoreErrors = false): Observable<FolioDocument> {
    return this.store.select(selectIndexedDocuments).pipe(
      take(1),
      map(indexedDocs => indexedDocs),
      switchMap(index => (!!index && !!index[docId]) ? of(index[docId]) : this.getFolioDocument(docId, ignoreErrors)),
      take(1)
    );
  }

  /**
   * Get one folio document
   */
  getFolioDocument(docId: string, ignoreErrors = false): Observable<FolioDocument> {
    const url = UrlUtil.folioServerUrl('/api/v1/documents/document');
    return this.http.get(url, { params: { documentId: docId } }).pipe(
      tap((document: FolioDocument) => this.store.dispatch(FolioActions.indexDocuments({folioDocuments: [document]}))),
      map(document => <FolioDocument> document),
      catchError(ignoreErrors ?
        this.errorService.doNothing() :
        this.errorService.logAndRouteToStatus<FolioDocument>())
    );
  }

  /**
   * Get the document's download URL
   */
  getFolioDocumentDownloadLink(docId: string): Observable<string> {
    const url = UrlUtil.folioServerUrl('/api/v1/documents/download-url');
    return this.http.get(url, { params: { documentId: docId }, responseType: 'text' }).pipe(
      map(downloadUrl => <string> downloadUrl),
      catchError(this.errorService.logAndRouteToStatus<string>()));
  }

  /**
   * Query for folio documents
   */
  queryFolioDocuments(folioDocumentRequest: FolioDocumentRequest): Observable<FolioPageResult> {
    const url = UrlUtil.folioServerUrl('/api/v1/documents');
    return this.http.post(url, folioDocumentRequest).pipe(
      map(result => <FolioPageResult> result),
      catchError(this.errorService.logAndRouteToStatus<FolioPageResult>()));
  }

  /**
   * Get a secure ZIP cookie from the Folio API
   */
  getSecureZipCookie(docId: string, forSharedLink = false): Observable<string> {
    const url = forSharedLink ?
      UrlUtil.folioServerUrl('/api/v1/secure-cookie/shared') :
      UrlUtil.folioServerUrl('/api/v1/secure-cookie/cookie');
    return this.http.get(url, { params: { documentId: docId }, responseType: 'text' }).pipe(
      map(cookie => <string> cookie),
      catchError(this.errorService.logAndRouteToStatus<string>()));
  }

  /**
   * Get search suggestions from the Folio API
   */
  getSearchSuggestions(freeText: string): Observable<SearchSuggestions> {
    const url = UrlUtil.folioServerUrl('/api/v1/documents/search-suggestions');
    return this.http.get(url, { params: { freeText } }).pipe(
      map(suggestions => <SearchSuggestions> suggestions),
      catchError(this.errorService.logModalAndContinue<SearchSuggestions>()));
  }

  // FolioDocumentMetrics
  // -------------------------------------------------------------------------------------------------------------------

  /**
   * Get the metrics for a given document id
   */
  getFolioDocumentMetrics(docId: string): Observable<FolioDocumentMetrics> {
    const url = UrlUtil.folioServerUrl('/api/v1/documents/metrics');
    return this.http.get(url, { params: { documentId: docId } }).pipe(
      map(metrics => <FolioDocumentMetrics> metrics),
      catchError(this.errorService.logAndRouteToStatus<FolioDocumentMetrics>()));
  }


  // FolioPublicDocumentLink
  // -------------------------------------------------------------------------------------------------------------------

  /**
   * Get a sorted/paginated list of FolioPublicDocumentLink for the current user
   */
  getPublicDocumentLinks(size = 0,
    page = 0,
    sortBy = 'timestamp',
    sortDesc = true): Observable<any> {
    const url = UrlUtil.folioServerUrl('/api/v1/public-doc-links');

    // 'expired' is a field calculated on the frontend so can't be sorted on the backend. It is directly correlated with expirationDate
    sortBy = sortBy === 'expired' ? 'expirationDate' : sortBy;
    return this.http.get(url, { params: { size, page, sortBy, sortDesc } }).pipe(
      map(page => <FolioPublicDocumentLinksPage> page),
      catchError(this.errorService.logAndRouteToStatus<FolioDocument>()));
  }

  /**
   * Generate (or update) a FolioPublicDocumentLink
   */
  generatePublicDocumentLink(publicDocumentLinkRequest: FolioPublicDocumentLinkRequest, regenerate = false):
    Observable<FolioPublicDocumentLinkResponse> {
    const url = UrlUtil.folioServerUrl('/api/v1/public-doc-links');
    return this.http.post(url, publicDocumentLinkRequest).pipe(
      map((response: FolioPublicDocumentLinkResponse) => response),
      catchError(regenerate ?
        this.errorService.showShareErrorModal<FolioPublicDocumentLinkResponse>() :
        this.errorService.logAndRouteToStatus<FolioPublicDocumentLinkResponse>()
      )
    );
  }

  /**
   * Expire a FolioPublicDocumentLink
   */
  expirePublicDocumentLink(id: string): Observable<FolioPublicDocumentLinkResponse> {
    const url = UrlUtil.folioServerUrl('/api/v1/public-doc-links/expire');
    return this.http.post(url, null , { params: { id } }).pipe(
      map((response: FolioPublicDocumentLinkResponse) => response),
      catchError(this.errorService.logAndRouteToStatus<FolioPublicDocumentLinkResponse>())
    );
  }

  /**
   * Get the FolioDocument for the given FolioPublicDocumentLink id
   * The API will convert CDN URLs to their publicly accessible version
   */
  getDocumentForPublicLinkId(id: string): Observable<FolioDocument> {
    const url = UrlUtil.folioServerUrl('/api/v1/public-doc-links/view');
    return this.http.get(url, { params: { id } }).pipe(
      map(document => <FolioDocument> document),
      catchError(this.errorService.logAndRouteToPublicDocument404<FolioDocument>()));
  }

  /**
   * Download the share history for the currently logged in user.
   */
  exportCsvForCreator(): Observable<Blob> {
    const url = UrlUtil.folioServerUrl('/api/v1/public-doc-links/csv/download');
    return this.http.get(url, { responseType: 'blob' }).pipe(
      catchError(this.errorService.logAndRouteToStatus<any>()));
  }
}
