import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { MessagingService } from '@core/services/messaging.service';
import {
  FolioDocument,
  FolioPublicDocumentLink,
  FolioPublicDocumentLinkRequest,
  FolioPublicDocumentLinkResponse,
  FolioPublicDocumentLinkShareType
} from '@core/models';
import { DocumentService } from '@core/services/document.service';
import { FolioState } from '../../../../ngrx/store';
import { DocUtil } from '@core/utils/doc.util';
import { selectPublicLinksExpireAfterDays } from '../../../../ngrx/selectors';
import { FomanticFormUtil } from '@core/utils/fomantic-form.util';

@Component({
  selector:    'folio-document-1ink-modal',
  templateUrl: './document-link-modal.component.html',
  styleUrls:   ['./document-link-modal.component.less']
})
export class DocumentLinkModalComponent implements OnInit, AfterViewInit, OnDestroy {

  document: FolioDocument;
  $modal: JQuery<Element>;
  subscription: Subscription;
  copied: boolean;
  publicUrl: string;
  folioPublicDocumentLink: FolioPublicDocumentLink;
  linkExpireAfterDays$: Observable<number>;
  showLinkAlreadyExistsMessage: boolean;

  @ViewChild('urlCopyTarget')
  private urlCopyTarget: ElementRef;
  @ViewChild('emailForm')
  private emailForm: ElementRef;
  @ViewChild('commaSeparatedEmails')
  private commaSeparatedEmails: ElementRef;

  constructor(private el: ElementRef,
              private store: Store<FolioState>,
              private messagingService: MessagingService,
              private documentService: DocumentService) {
  }

  ngOnInit(): void {
    this.linkExpireAfterDays$ = this.store.pipe(select(selectPublicLinksExpireAfterDays));
    this.subscription         = this.messagingService.documentForShareModal
      .pipe(
        tap(docId => {
          if (!docId) {
            this.close();
          }
        }),
        filter(docId => !!docId),
        switchMap((docId: string) => this.documentService.getIndexedFolioDocument(docId))
      ).subscribe((doc) => {
        this.document = doc;
        this.$modal.modal('show');
      });
  }

  ngAfterViewInit() {
    this.$modal = $(this.el.nativeElement).modal({
      duration: 200,
      onHide:   () => {
        this.cleanUpForm();
      },
      onShow: () => {
        this.initForm();
      }
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  handleGenerateLinkClick() {
    $(this.emailForm.nativeElement).form('submit');
  }

  handleCopyClick() {
    this.urlCopyTarget.nativeElement.select();
    document.execCommand('copy');
    this.urlCopyTarget.nativeElement.selectionStart = this.urlCopyTarget.nativeElement.selectionEnd = 0;
    this.copied                                     = true;

    // Change back to Copy button after 3 seconds
    setTimeout(() => {
      this.copied = false;
    }, 3000);
  }

  handleCloseClick() {
    this.close();
  }

  createLink() {
    const toList                                                    = this.commaSeparatedEmails.nativeElement?.value?.split(',').filter(v => !!v).map(v => v.trim()).filter(v => !!v);
    const publicDocumentLinkRequest: FolioPublicDocumentLinkRequest = {
      shareType:  FolioPublicDocumentLinkShareType.LINK,
      documentId: DocUtil.getId(this.document),
      to:         toList.length > 0 ? toList : null
    };
    this.documentService.generatePublicDocumentLink(publicDocumentLinkRequest)
      .subscribe((response: FolioPublicDocumentLinkResponse) => {
        this.showLinkAlreadyExistsMessage = !response.created;
        this.folioPublicDocumentLink      = response.folioPublicDocumentLink;
        this.publicUrl                    = `${window?.location.origin}/shared/${this.folioPublicDocumentLink.id}`;
      });
  }

  initForm() {
    $.fn.form.settings.rules.commaSeparatedEmails = (value) => {
      const emailList = value?.split(',').filter(v => !!v).map(v => v.trim()).filter(v => !!v) ?? [];
      return !!emailList.length && emailList.every(to => FomanticFormUtil.emailIsValid(to));
    };

    $(this.emailForm.nativeElement).form({
      inline:    true,
      onSuccess: () => {
        this.createLink();
      },
      fields: {
        commaSeparatedEmails: {
          identifier: 'commaSeparatedEmails',
          rules:      [
            {
              type:   'commaSeparatedEmails',
              prompt: 'Please enter a comma separated list of email addresses.'
            }
          ]
        }
      }
    });
  }

  cleanUpForm() {
    if (this.emailForm) {
      $(this.emailForm.nativeElement).form('reset');
    }
    this.folioPublicDocumentLink = null;
  }

  close() {
    this.$modal?.modal('hide');
  }
}
