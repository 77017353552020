import { SentryConfig } from './app.model';

export class ToolingConfiguration {

  /**
   * Sentry configuration
   * ----------------------------------------------------------------------
   */
  static SENTRY: {[hostEnv: string]: SentryConfig} = {
    dev: {
      dsn: 'https://e63626f824da4745b292b31fba488feb@o12195.ingest.sentry.io/5518678'
    },
    val: {
      dsn: 'https://dbb5055042f44457b05e6ff1845f738f@o12195.ingest.sentry.io/5518678'
    },
    prod: {
      dsn: 'https://0964fdc4f69b4b5cbd6b69bf41189437@o12195.ingest.sentry.io/5518678'
    }
  };

  /**
   * NewRelic initialization functions
   * ----------------------------------------------------------------------
   */
  static NEWRELIC_INITS: {[hostEnv: string]: any} = {
    dev: () => {
      (<any> window).NREUM.loader_config = {
        accountID:     '631235',
        trustKey:      '631235',
        agentID:       '498311185',
        licenseKey:    '783833c0ed',
        applicationID: '498311185'
      };
      (<any> window).NREUM.info          = {
        beacon:        'bam-cell.nr-data.net',
        errorBeacon:   'bam-cell.nr-data.net',
        licenseKey:    '783833c0ed',
        applicationID: '498311185',
        sa:            1
      };
    },
    val: () => {
      (<any> window).NREUM.loader_config = {
        accountID:     '631235',
        trustKey:      '631235',
        agentID:       '498334254',
        licenseKey:    '783833c0ed',
        applicationID: '498334254'
      };
      (<any> window).NREUM.info          = {
        beacon:        'bam-cell.nr-data.net',
        errorBeacon:   'bam-cell.nr-data.net',
        licenseKey:    '783833c0ed',
        applicationID: '498334254',
        sa:            1
      };
    },
    prod: () => {
      (<any> window).NREUM.loader_config = {
        accountID:     '631235',
        trustKey:      '631235',
        agentID:       '498334825',
        licenseKey:    '783833c0ed',
        applicationID: '498334825'
      };
      (<any> window).NREUM.info          = {
        beacon:        'bam-cell.nr-data.net',
        errorBeacon:   'bam-cell.nr-data.net',
        licenseKey:    '783833c0ed',
        applicationID: '498334825',
        sa:            1
      };
    }
  };
}
