/* eslint-disable no-case-declarations */
import { DataUtil } from './data.util';
import {
  AppliedFilterLabel,
  CheckboxFilter,
  Filter,
  FilterKeyOptionMap,
  FilterType,
  FolioPageResult,
  LibraryFilterConfig,
  LibraryPagingData,
  MultiSelectFilter,
  RequestSort
} from '../models';
import { FolioLibraryQueryParam, FolioState, LibraryState } from '../../ngrx/store';
import { Params } from '@angular/router';
import { UrlUtil } from './url.util';

export class FilterUtil {

  static getQParam(queryParams: Params = {}): FolioLibraryQueryParam {
    try {
      return UrlUtil.decode(queryParams.q);
    } catch {
      return {};
    }
  }

  /**
   * Clean up the qParam by removing any empty filters lists and remove any defaults from the qParam
   */
  static cleanUpQParam(qParam: FolioLibraryQueryParam = {}): FolioLibraryQueryParam {
    if (!qParam.text) {
      delete qParam.text;
    }

    if (qParam.filters) {
      qParam.filters = Object.keys(qParam.filters).reduce((agg, key) => {
        if (qParam.filters[key]?.length) {
          agg[key] = qParam.filters[key];
        }
        return agg;
      }, {});
      if (DataUtil.isEmpty(qParam.filters)) {
        delete qParam.filters;
      }
    }

    if (!qParam.favorites) {
      delete qParam.favorites;
    }

    if (qParam.start === 0) {
      delete qParam.start;
    }

    return qParam;
  }

  static getLibraryPagingData(fpr: FolioPageResult): LibraryPagingData {
    const from        = fpr.totalResults === 0 ? 0 : fpr.start + 1;
    const to          = fpr.start + fpr.folioDocuments?.length;
    const total       = fpr.totalResults;
    const hasPrevious = fpr.start > 0;
    const hasNext     = fpr.hasMore;
    return <LibraryPagingData> { from, to, total, hasPrevious, hasNext };
  }

  static getPendingBasedOnApplied(appliedFilters: Filter[]): FilterKeyOptionMap {
    const pendingFilterMap: FilterKeyOptionMap = {};

    (appliedFilters || [])
      .filter(f => FilterUtil.isFilterAffectingQuery(f))
      .forEach(filter => {
        switch (filter.type) {
          case FilterType.CHECKBOX_GROUP:
            const checkboxFilter            = (<CheckboxFilter> filter);
            const checkboxOptions: string[] = [];
            checkboxFilter.options.filter(cbfo => cbfo.checked).forEach(cbfo => {
              checkboxOptions.push(cbfo.display);
            });
            pendingFilterMap[checkboxFilter.filterId] = checkboxOptions;
            break;
          case FilterType.MULTI_SELECT:
            const multiSelectFilter                      = (<MultiSelectFilter> filter);
            pendingFilterMap[multiSelectFilter.filterId] = (<MultiSelectFilter> filter).selectedValues;
            break;
        }
      });

    return pendingFilterMap;
  }

  static getFiltersWithPendingApplied(baselineFilters: Filter[], pendingFilterKeyOptions: FilterKeyOptionMap): Filter[] {
    const appliedFilters = DataUtil.clone(baselineFilters);
    if (!pendingFilterKeyOptions) {
      return appliedFilters;
    }

    for (const pfKey of Object.keys(pendingFilterKeyOptions)) {
      const filterToUpdate       = appliedFilters.find((f: { filterId: string; type: any; }) => f.filterId === pfKey && f.type);
      const pendingFilterOptions = pendingFilterKeyOptions[pfKey];

      if (!filterToUpdate) {
        continue;
      }

      switch (filterToUpdate.type) {
        case FilterType.CHECKBOX_GROUP:
          const checkboxFilter = (<CheckboxFilter> filterToUpdate);
          checkboxFilter.options.forEach(o => {
            o.checked = pendingFilterOptions.includes(o.display);
          });
          break;
        case FilterType.MULTI_SELECT:
          const multiSelectFilter          = (<MultiSelectFilter> filterToUpdate);
          multiSelectFilter.selectedValues = pendingFilterOptions;
          multiSelectFilter.options.forEach(o => {
            o.selected = pendingFilterOptions.includes(o.display);
          });
          break;
      }
    }

    return appliedFilters;
  }

  static clearAppliedFilter(currentAppliedFilters: Filter[], appliedFilterLabel: AppliedFilterLabel): Filter[] {
    const appliedFilters = DataUtil.clone(currentAppliedFilters);
    const filterToUpdate = appliedFilters.find((f: { filterId: string; }) => f.filterId === appliedFilterLabel.filterId);

    switch (filterToUpdate.type) {
      case FilterType.CHECKBOX_GROUP:
        const checkboxFilter                                                                         = (<CheckboxFilter> filterToUpdate);
        checkboxFilter.options.find(cfo => cfo.display === appliedFilterLabel.optionDisplay).checked = false;
        break;
      case FilterType.MULTI_SELECT:
        const multiSelectFilter                                                                            = (<MultiSelectFilter> filterToUpdate);
        multiSelectFilter.options.find(msfo => msfo.display === appliedFilterLabel.optionDisplay).selected = false;
        const newSelectedValues: string[]                                                                  = [];
        multiSelectFilter.options.filter(o => o.selected).forEach(o => {
          o.value.forEach(v => newSelectedValues.push(v));
        });
        multiSelectFilter.selectedValues = newSelectedValues;
        break;
    }

    return appliedFilters;
  }

  static getAppliedFilterLabels(appliedFilters: Filter[]): AppliedFilterLabel[] {
    const appliedFilterLabels: AppliedFilterLabel[] = [];

    appliedFilters.forEach((appliedFilter: Filter) => {
      switch (appliedFilter.type) {
        case FilterType.CHECKBOX_GROUP:
          const checkboxFilter = (<CheckboxFilter> appliedFilter);
          checkboxFilter.options.filter(cbfo => cbfo.checked).forEach(cbfo => {
            appliedFilterLabels.push({
              filterId:      appliedFilter.filterId,
              filterLabel:   appliedFilter.label,
              optionDisplay: cbfo.display
            });
          });
          break;
        case FilterType.MULTI_SELECT:
          const multiSelectFilter = (<MultiSelectFilter> appliedFilter);
          multiSelectFilter.options.filter(msfo => msfo.selected).forEach(msfo => {
            appliedFilterLabels.push({
              filterId:      appliedFilter.filterId,
              filterLabel:   appliedFilter.label,
              optionDisplay: msfo.display
            });
          });
          break;
      }
    });

    return appliedFilterLabels;
  }

  static getRSQLForAppliedFilters(state: FolioState): string {
    const libraryState = state.library;

    const appliedFilters    = DataUtil.clone(libraryState.appliedFilterConfig.filters);
    const appliedRequest    = DataUtil.clone(libraryState.appliedRequest);
    const showOnlyFavorites = libraryState.appliedFilterConfig.onlyFavorites;

    const filterExpression    = FilterUtil.and.apply(null, appliedFilters.filter(FilterUtil.isFilterAffectingQuery).map(FilterUtil.filterToQueryExpression));
    const hasFilterExpression = filterExpression !== '';

    const freeText    = appliedRequest.folioFilters.freeText;
    const hasFreeText = freeText && freeText.length;

    const allFilters = hasFilterExpression ? [filterExpression] : [];
    if (hasFreeText) {
      allFilters.push(FilterUtil.ft('freeText', freeText));
    }

    if (showOnlyFavorites) {
      const favIds = state.favorites?.documentIds ?? [];
      if (favIds.length) {
        allFilters.push(FilterUtil.in('id', favIds));
      } else {
        // they want to see only favorites, but they have none, so filter by an id we know won't exist :)
        allFilters.push(FilterUtil.eq('id', 'xxx'));
      }
    }

    return FilterUtil.and.apply(null, allFilters);
  }

  static areAnyFiltersApplied(appliedFilters: Filter[]): boolean {
    return appliedFilters.some(FilterUtil.isFilterAffectingQuery);
  }

  static numberOfFiltersApplied(lfc: LibraryFilterConfig): number {
    const appliedFilters: Filter[] = lfc.filters;
    return appliedFilters.reduce((total, filter) => {
      return total + FilterUtil.numberOfOptionsApplied(filter);
    }, (lfc.onlyFavorites ? 1 : 0));
  }

  static numberOfOptionsApplied(filter: Filter): number {
    let total = 0;
    if (FilterUtil.isFilterAffectingQuery(filter)) {
      switch (filter.type) {
        case FilterType.CHECKBOX_GROUP:
          (<CheckboxFilter> filter).options.forEach(o => {
            total = total + (o.checked ? 1 : 0);
          });
          break;
        case FilterType.MULTI_SELECT:
          (<MultiSelectFilter> filter).options.forEach(o => {
            total = total + (o.selected ? 1 : 0);
          });
      }
    }
    return total;
  }

  static RELEVANCY_SORT_OPTION = {
    sortId:  'relevancy',
    on:      false,
    hidden:  true,
    display: 'Relevance',
    value:   { attribute: '', descending: true }
  };

  static getRequestSortBasedOnOptions(libraryState: LibraryState): RequestSort {
    const sortToApply = libraryState.appliedFilterConfig.sortOptions.find(so => so.on);
    return new RequestSort(sortToApply.value.attribute, sortToApply.value.descending);
  }

  static getBaseSortId(libraryState: LibraryState): string {
    return libraryState.baseFilterConfig.sortOptions.find(so => so.on).sortId;
  }

  static filterToQueryExpression(filter: Filter): string {
    switch (filter.type) {
      case FilterType.CHECKBOX_GROUP:
        return FilterUtil.checkboxFilterToQueryExpression(<CheckboxFilter> filter);
      case FilterType.MULTI_SELECT:
        return FilterUtil.multiSelectFilterToQueryExpression(<MultiSelectFilter> filter);
      default:
        throw new Error('I don\'t know how to build a query expression for filters of type ' + filter.type);
    }
  }

  static isFilterAffectingQuery(filter: Filter): boolean {
    if (!filter) {
      return false;
    }

    switch (filter.type) {
      case FilterType.CHECKBOX_GROUP:
        return FilterUtil.isCheckboxFilterAffectingQuery(<CheckboxFilter> filter);
      case FilterType.MULTI_SELECT:
        return FilterUtil.isMultiSelectFilterAffectingQuery(<MultiSelectFilter> filter);
      default:
        throw new Error('I don\'t know how to determine if a filter of type ' + filter.type + ' is active.');
    }
  }

  static multiSelectFilterToQueryExpression(filter: MultiSelectFilter): string {
    const selectedValues = filter.options.filter(o => o.selected).map(o => o.value);
    const flattened      = DataUtil.flatten(selectedValues);

    return FilterUtil.in(filter.fieldPath, flattened);
  }

  static checkboxFilterToQueryExpression(filter: CheckboxFilter): string {
    const checkedValues = filter.options.filter(o => o.checked).map(o => o.value);
    const flattened     = DataUtil.flatten(checkedValues);
    const normalFilters = FilterUtil.in(filter.fieldPath, flattened);

    return flattened.length ? normalFilters : null;
  }

  static isMultiSelectFilterAffectingQuery(filter: MultiSelectFilter): boolean {
    return filter.options.some(o => o.selected);
  }

  static isCheckboxFilterAffectingQuery(filter: CheckboxFilter): boolean {
    return filter.options.some(o => o.checked);
  }

  static wrap(...args: string[]): string {
    const filteredArgs = args.filter(DataUtil.isNotBlank);
    return filteredArgs.length ? ('(' + Array.prototype.slice.call(filteredArgs).join('') + ')') : '';
  }

  static or(...args: string[]): string {
    return Array.prototype.slice.call(args).filter(DataUtil.isNotBlank).join(',');
  }

  static and(...args: string[]): string {
    return Array.prototype.slice.call(args).filter(DataUtil.isNotBlank).join(';');
  }

  static gt(k: any, v: any) {
    const serialized = FilterUtil.serialize(v);
    return `${k}=gt=${serialized}`;
  }

  static lt(k: any, v: any) {
    const serialized = FilterUtil.serialize(v);
    return `${k}=lt=${serialized}`;
  }

  static gte(k: any, v: any) {
    const serialized = FilterUtil.serialize(v);
    return `${k}=ge=${serialized}`;
  }

  static lte(k: any, v: any) {
    const serialized = FilterUtil.serialize(v);
    return `${k}=le=${serialized}`;
  }

  static eq(k: string, v: string) {
    if (v == null) {
      return FilterUtil.exists(k, false);
    } else {
      const serialized = FilterUtil.serialize(v);
      return `${k}==${serialized}`;
    }
  }

  static ne(k: any, v: any) {
    if (v == null) {
      return FilterUtil.exists(k, true);
    } else {
      const serialized = FilterUtil.serialize(v);
      return `${k}!=${serialized}`;
    }
  }

  static ft(k: string, v: any) {
    const serialized = FilterUtil.serialize(v);
    return `${k}=ft=${serialized}`;
  }

  static in(k: string, values: any[]) {
    const expanded = FilterUtil.expand(values);
    return `${k}=in=(${expanded})`;
  }

  static nin(k: any, values: any) {
    const expanded = FilterUtil.expand(values);
    return `${k}=out=(${expanded})`;
  }

  static exists(k: any, b: boolean) {
    const serialized = FilterUtil.serialize(b);
    return `${k}=ex=${serialized}`;
  }

  static expand(values) {
    return values.map(FilterUtil.serialize).join(',');
  }

  static serialize(v) {
    return (typeof v === 'boolean') ? v : JSON.stringify((v || '').toString());
  }
}
