import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { MessagingService } from '@core/services/messaging.service';
import { FolioActions } from './ngrx/actions';
import { FolioState } from './ngrx/store';
import { select, Store } from '@ngrx/store';
import { filter, switchMap } from 'rxjs/operators';
import { SentryUtil } from '@core/utils/sentry.util';
import { isUserSet } from './ngrx/selectors';
import jwt_decode from 'jwt-decode';
import { UrlUtil } from '@core/utils/url.util';
import { RouterService } from '@core/services/router.service';
import { Meta } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SwLogUpdateService } from '@core/services/sw-log-update.service';

@UntilDestroy()
@Component({
  selector:    'folio-root',
  templateUrl: './app.component.html',
  styleUrls:   ['./app.component.less']
})
export class AppComponent implements OnDestroy, OnInit {

  authIsLoading$: Observable<boolean> = this.authService.isLoading$;
  isUserSet$: Observable<boolean>;
  isPubliclyAccessiblePage = false;

  private subscription: Subscription = new Subscription();

  constructor(private store: Store<FolioState>,
              private authService: AuthService,
              private messagingService: MessagingService,
              private metaService: Meta,
              private routerService: RouterService,
              private swLogUpdateService: SwLogUpdateService
  ) {
    this.routerService.listen();
    this.isUserSet$ = this.store.pipe(select(isUserSet));
  }

  ngOnInit() {
    // if it's a publicly accessible page (like an error page) need the template to just show the
    // <router-outlet> and not wait around for an isAuthenticated response that will never come
    this.isPubliclyAccessiblePage = UrlUtil.isPubliclyAccessiblyPage();

    this.subscription.add(
      this.authService.error$.subscribe((error) => {
        this.messagingService.emitDataForErrorModal({ message: error?.message });
      })
    );

    this.subscription.add(
      combineLatest([
        this.authService.isLoading$,
        this.authService.isAuthenticated$
      ]).pipe(
        filter(([isLoading, isAuthenticated]) => !isLoading && isAuthenticated),
        switchMap(_ => {
          return combineLatest([
            this.authService.user$,
            this.authService.getAccessTokenSilently()
          ]);
        })
      ).subscribe(([user, jwt]) => {
        if (this.isFirstLogin(jwt)) {
          // workaround for known Auth0 issue where the very first login requires a re-fetch of their token
          window.location.reload();
        } else {
          this.setUser(user, jwt);
          this.setUserMetaTags(user);
        }
      })
    );

    this.swLogUpdateService.getUpdates()
      .pipe(
        untilDestroyed(this),
      ).subscribe();

  }

  setUser(user, jwt: string) {
    SentryUtil.setUser(user);
    this.store.dispatch(FolioActions.setUser({ user, jwt }));
  }

  setUserMetaTags(user) {
    this.metaService.addTags([
      { name: 'data-name', content: user.name },
      { name: 'data-email', content: user.email }
    ]);
  }

  isFirstLogin(jwt: string): boolean {
    const context = jwt_decode(jwt);
    return !!context && context['http://pepperapps.io/firstlogin'];
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
