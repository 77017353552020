import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { UrlUtil } from '@core/utils/url.util';
import { VodoriAuth0Config } from '@core/utils/app.model';
import { AuthConfig } from '@auth0/auth0-angular/lib/auth.config';

@Injectable({
  providedIn: 'root'
})
export class AuthConfigService {

  constructor(private http: HttpClient,
              private handler: HttpBackend,
              private authClientConfig: AuthClientConfig) {
  }

  /**
   * Load the authentication configuration json from the publicly available Folio API endpoint
   */
  load(): Promise<void> {
    const url = UrlUtil.folioServerUrl('/api/v1/auth0', {});
    return new Promise<void>((resolve, reject) => {
      this.http = new HttpClient(this.handler);
      this.http.get(url).toPromise().then((config: VodoriAuth0Config) => {
        if (UrlUtil.isPublicDocumentLinkPage()) {
          config.httpInterceptor = null;
        }
        // auth0-angular v2 has a different schema than what we used in VodoriAuth0Config
        const authConfig: AuthConfig = {
          domain:              config.domain,
          clientId:            config.clientId,
          httpInterceptor:     config.httpInterceptor,
          authorizationParams: {
            prompt:       'select_account',
            audience:     config.audience,
            scope:        config.scope,
            redirect_uri: window.location.origin
          }
        };

        this.authClientConfig.set(authConfig);
        resolve();
      });
    });
  }
}
