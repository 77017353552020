import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { authGuardFn } from '@auth0/auth0-angular';
import { SharesGuard } from './core/guards/shares.guard';

export const FOLIO_ROUTES: Routes = [
  {
    path:      '',
    pathMatch: 'prefix',
    children:  [
      {
        path:         'home',
        pathMatch:    'full',
        canActivate:  [authGuardFn],
        loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule)
      }, {
        path:         'library',
        pathMatch:    'full',
        canActivate:  [authGuardFn],
        loadChildren: () => import('./features/library/library.module').then(m => m.LibraryModule)
      }, {
        path:         'viewer/:id',
        pathMatch:    'full',
        canActivate:  [authGuardFn],
        loadChildren: () => import('./features/viewer/viewer.module').then(m => m.ViewerModule)
      }, {
        path:         'profile',
        canActivate:  [authGuardFn],
        loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule)
      }, {
        path:         'collections',
        canActivate:  [authGuardFn],
        loadChildren: () => import('./features/collections/collections.module').then(m => m.CollectionsModule)
      }, {
        path:         'shared/:id',
        loadChildren: () => import('./features/viewer/public-viewer.module').then(m => m.PublicViewerModule)
      }, {
        path:         'shares',
        canActivate:  [authGuardFn, SharesGuard],
        loadChildren: () => import('./features/shares/shares.module').then(m => m.SharesModule)
      }, {
        path:         'error',
        loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
      }, {
        path:       '',
        pathMatch:  'full',
        redirectTo: '/home'
      }, {
        path:       '**',
        redirectTo: '/home'
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(FOLIO_ROUTES, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
