import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  private previousUrl: string;
  private currentUrl: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  public listen() {
    this.router.events.pipe(
      filter((e: any) => e instanceof RoutesRecognized),
      pairwise()
    ).subscribe((e: any) => {
      this.previousUrl = e[0]?.urlAfterRedirects;
      this.currentUrl  = e[1]?.urlAfterRedirects;
    });
  }

  public getPreviousUrl(): string {
    return this.previousUrl;
  }

  public updateQueryParamAndNavigate(name: string, value: string, route = '/') {
    const queryParams: any = Object.assign({}, this.activatedRoute.snapshot.queryParams);
    queryParams[name]      = value;
    this.router.navigate([route], { relativeTo: this.activatedRoute, queryParams });
  }
}
