import { ErrorHandler, Injectable } from '@angular/core';
import { SentryUtil } from '../utils/sentry.util';

@Injectable()
export class FolioErrorHandler implements ErrorHandler {

  /**
   * Handle any uncaught exceptions that may occur and notify Sentry
   */
  handleError(error: any): void {
    if (error.status !== 401) {
      SentryUtil.sendSentryException(error);
    }
    throw error;
  }

}
