<ng-container *ngIf="isPubliclyAccessiblePage; else isNotPubliclyAccessiblePage">
    <router-outlet></router-outlet>
</ng-container>
<ng-template #isNotPubliclyAccessiblePage>
    <ng-container *ngIf="((authIsLoading$ | async) || !(isUserSet$ | async)); else appReady">
        <folio-loader></folio-loader>
    </ng-container>
    <ng-template #appReady>
        <router-outlet></router-outlet>

        <folio-document-actions-modal class="ui mini modal"></folio-document-actions-modal>
        <folio-document-details-modal class="ui modal"></folio-document-details-modal>
        <folio-error-modal class="ui mini modal"></folio-error-modal>
        <folio-document-1ink-modal class="ui tiny modal"></folio-document-1ink-modal>

        <folio-modals></folio-modals>
    </ng-template>
</ng-template>



