import { FetchProgress } from './store';
import { distinctUntilChanged } from 'rxjs/operators';
import { DataUtil } from '../core/utils/data.util';

/**
 * Utility functions for the FolioState
 */
export class StateUtil {

  static isNotLoading(fp: FetchProgress) {
    return !fp || fp === FetchProgress.NONE;
  }

  static isLoading(fp: FetchProgress) {
    return !!fp && fp === FetchProgress.LOADING;
  }

  static isLoaded(fp: FetchProgress) {
    return !!fp && fp === FetchProgress.LOADED;
  }

  static isNotLoaded(fp: FetchProgress) {
    return !StateUtil.isLoaded(fp);
  }

  static deepDistinctUntilChanged() {
    return distinctUntilChanged(DataUtil.deepEquals);
  }

  // Viewer display
  // ----------------------------------------------------

  static incrementScale(currentScale: number) {
    const multiplier = 1 / SCALE_DELTA;
    let newScale     = currentScale + SCALE_DELTA;
    newScale         = Number((Math.round(newScale * multiplier) / multiplier).toFixed(2));
    newScale         = Math.min(MAXIMUM_ZOOM_SCALE, newScale);

    return newScale;
  }

  static decrementScale(currentScale: number) {
    const multiplier = 1 / SCALE_DELTA;
    let newScale     = currentScale - SCALE_DELTA;
    newScale         = Number((Math.round(newScale * multiplier) / multiplier).toFixed(2));
    newScale         = Math.min(MAXIMUM_ZOOM_SCALE, newScale);
    newScale         = Math.max(MINIMUM_ZOOM_SCALE, newScale);

    return newScale;
  }
}

export const MINIMUM_ZOOM_SCALE = .25;
export const MAXIMUM_ZOOM_SCALE = 5;
export const SCALE_DELTA = .25;
