<ng-container *ngIf="!!errorData">
    <div class="header">
        <ng-container *ngIf="!!errorData.title; else noTitle">
            <span>{{errorData.title}}</span>
        </ng-container>
        <ng-template #noTitle>
            <span>Sorry, something went wrong</span>
        </ng-template>
    </div>
    <div class="content">
        <folio-icon-message [messageClasses]="'warning'" [iconClasses]="'exclamation triangle'">
            <ng-container *ngIf="!!errorData.message; else noMessage">
                <p>{{errorData.message}}</p>
            </ng-container>
            <ng-template #noMessage>
                <p>Something went wrong, and we could not complete your request.</p>
            </ng-template>
            <ng-container *ngIf="!errorData.noRefresh">
                <p>Please refresh the page and try again.</p>
            </ng-container>
        </folio-icon-message>
    </div>
    <div class="link actions">
        <ng-container *ngIf="errorData.noRefresh; else refresh">
            <a class="ui cancel">Close</a>
        </ng-container>
        <ng-template #refresh>
            <a (click)="handleRefreshClick()">Refresh</a>
        </ng-template>
    </div>
</ng-container>
