import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { FolioState } from '@app/ngrx/store';
import { FolioActions } from '@app/ngrx/actions';
import {
  canUserCreateCollection,
  canUserDownloadContent,
  canUserFavoriteContent,
  canUserShareContent,
  isDocAFavorite,
  selectUrl
} from '@app/ngrx/selectors';
import { MessagingService } from '@core/services/messaging.service';
import { FolioDocument } from '@core/models';
import { DocUtil } from '@core/utils/doc.util';
import { DocumentService } from '@core/services/document.service';
import { FolioModalType } from '@core/models/modals.model';

@Component({
  selector:    'folio-document-actions-modal',
  templateUrl: './document-actions-modal.component.html',
  styleUrls:   ['./document-actions-modal.component.less']
})
export class DocumentActionsModalComponent implements OnInit, AfterViewInit, OnDestroy {

  _document: FolioDocument;
  isCollectionViewMode: boolean;
  collectionId: string;

  set document(doc: FolioDocument) {
    this._document   = doc;
    this.isFavorite$ = this.store.pipe(select(isDocAFavorite, doc));
  }
  get document() {
    return this._document;
  }

  showViewContentLink = false;
  $modal: JQuery<HTMLElement>;
  subscription: Subscription;
  isFavorite$: Observable<boolean>;

  canDownload$: Observable<boolean>;
  canUserFavorite$: Observable<boolean>;
  canUserShare$: Observable<boolean>;
  canUserCreateCollection$: Observable<boolean>;

  constructor(private el: ElementRef,
              private store: Store<FolioState>,
              private messagingService: MessagingService,
              private documentService: DocumentService,
              private router: Router) {
    this.canDownload$             = store.pipe(select(canUserDownloadContent));
    this.canUserFavorite$         = store.pipe(select(canUserFavoriteContent));
    this.canUserShare$            = store.pipe(select(canUserShareContent));
    this.canUserCreateCollection$ = store.pipe(select(canUserCreateCollection));
  }

  ngOnInit(): void {
    this.subscription =
      this.messagingService.documentForActionsModal
        .pipe(
          filter((res) => !!res),
          map((res: {folioDocument: FolioDocument, collectionId: string}) => {
            return { docId: DocUtil.getId(res.folioDocument), collectionId: res.collectionId };
          }),
          tap((res: {docId: string; collectionId: string | null}) => {
            if (!res.docId) {
              this.close();
            }
            this.collectionId = res.collectionId;
          }),
          filter((res: {docId: string; collectionId: string | null}) => !!res.docId),
          switchMap((res: {docId: string; collectionId: string | null}) => this.documentService.getIndexedFolioDocument(res.docId)),
          withLatestFrom(this.store.pipe(select(selectUrl))),
        ).subscribe(([doc, url]) => {
          this.document = doc;
          this.$modal.modal('show');
          this.showViewContentLink  = !url.startsWith('/viewer');
          this.isCollectionViewMode = url.startsWith('/collections');
        });
  }

  ngAfterViewInit() {
    this.$modal = $(this.el.nativeElement).modal({
      duration: 200
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  handleViewContentClick() {
    this.router.navigate(['viewer', DocUtil.getId(this.document)]).then(() => {
      this.close();
    });
  }

  handleFavoriteClick() {
    this.store.dispatch(FolioActions.toggleFavorite({ id: DocUtil.getId(this.document) }));
  }

  handleShareClick() {
    this.messagingService.emitDocumentForShareModal(DocUtil.getId(this.document));
    this.close();
  }

  handleContentDetailsClick() {
    this.messagingService.emitDocumentForDetailsModal(this.document);
    this.close();
  }

  handleDownloadClick() {
    this.store.dispatch(FolioActions.downloadDocument(this.document));
    this.close();
  }

  handleAddToCollectionClick() {
    this.store.dispatch(FolioActions.toggleModal(
      {
        modalType: FolioModalType.ADD_TO_COLLECTION,
        data:      {
          documentId: DocUtil.getId(this.document)
        }
      }));
  }

  handleRemoveFromCollectionClick() {
    this.store.dispatch(FolioActions.toggleModal(
      {
        modalType: FolioModalType.REMOVE_FROM_COLLECTION,
        data: {
          documents: [this.document],
          collectionId: this.collectionId
        }
      }));
  }

  handleCancelClick() {
    this.close();
  }

  close() {
    this.$modal?.modal('hide');
  }
}
