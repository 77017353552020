import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { MessagingService } from '@core/services/messaging.service';
import { FolioDocument, FolioDocumentMetrics, LabelValueType, PropertyType } from '@core/models';
import { FolioState } from '@app/ngrx/store';
import { select, Store } from '@ngrx/store';
import { selectModalPropertiesConfig, selectPublicLinksEnabled } from '@app/ngrx/selectors';
import { DocUtil } from '@core/utils/doc.util';
import { DocumentService } from '@core/services/document.service';
import { AuditActions } from '@core/models/audit.model';
import { AuditService } from '@core/services/audit.service';

@Component({
  selector:    'folio-document-details-modal',
  templateUrl: './document-details-modal.component.html',
  styleUrls:   ['./document-details-modal.component.less']
})
export class DocumentDetailsModalComponent implements OnInit, AfterViewInit, OnDestroy {

  document: FolioDocument;
  subscription: Subscription;
  $modal: JQuery<Element>;
  $tabContainer: JQuery<Element>;

  properties: LabelValueType[];
  propertyType = PropertyType;

  documentMetrics: FolioDocumentMetrics;

  sharingEnabled$: Observable<boolean>;

  constructor(private el: ElementRef,
              private store: Store<FolioState>,
              private documentService: DocumentService,
              private auditService: AuditService,
              private messagingService: MessagingService) {
    this.sharingEnabled$ = store.pipe(select(selectPublicLinksEnabled));
  }

  ngOnInit(): void {
    this.subscription =
      this.messagingService.documentForDetailsModal
        .pipe(
          map((folioDocument: FolioDocument) => {
            return DocUtil.getId(folioDocument);
          }),
          tap(docId => {
            if (!docId) {
              this.close();
            }
          }),
          filter(docId => !!docId),
          switchMap((docId: string) => this.documentService.getIndexedFolioDocument(docId)),
          withLatestFrom(this.store.pipe(select(selectModalPropertiesConfig)))
        ).subscribe(([doc, modalPropertiesConfig]) => {
          this.document = doc;
          this.auditService.createAudit(AuditActions.VIEW_DOCUMENT_PROPERTIES, doc);
          this.properties = modalPropertiesConfig.reduce((agg, prop) => {
            const lvt = DocUtil.getLabelValueType(doc, prop);
            if (lvt) {
              agg.push(lvt);
            }
            return agg;
          }, []);
          this.$modal.modal('show');
        });
  }

  ngAfterViewInit() {
    this.$modal = $(this.el.nativeElement).modal({
      duration: 200,
      onShow:   () => {
        if (!this.$tabContainer) {
          this.initTabContainer();
        }
      },
      onHide: () => {
        this.documentMetrics = null;
        this.$tabContainer?.tab('change tab', 'properties');
      }
    });
  }

  initTabContainer() {
    this.$tabContainer = $(this.el.nativeElement).find('.folio--tab-container .tabular.menu .item').tab({
      onLoad: (tabName) =>  {
        // performing a modal refresh here will ensure that the modal is repositioned based on how large it
        // needs to be for the content of the currently active tab within in
        this.$modal.modal('refresh');

        if (tabName === 'metrics') {
          this.fetchDocumentMetrics();
        }
      }
    });
  }

  fetchDocumentMetrics() {
    this.documentService.getFolioDocumentMetrics(DocUtil.getId(this.document)).subscribe(metrics => {
      this.documentMetrics = metrics;
      setTimeout(() => this.initMetricTooltips());
    });
  }

  initMetricTooltips() {
    $(this.el.nativeElement).find('.tooltip').popup({
      distanceAway: -10
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  handleCloseClick() {
    this.close();
  }

  close() {
    this.$modal?.modal('hide');
  }
}
