import { Pipe, PipeTransform } from '@angular/core';
import { FolioDocument } from '@core/models';
import { DocUtil } from '@core/utils/doc.util';
import { DataUtil } from '@core/utils/data.util';

@Pipe({
  name: 'docId'
})
export class DocIdPipe implements PipeTransform {
  transform(doc: FolioDocument, label = false): string {
    return DocUtil.getId(doc, label) ?? '';
  }
}

@Pipe({
  name: 'docVersion'
})
export class DocVersionPipe implements PipeTransform {
  transform(doc: FolioDocument, label = false): string {
    return DocUtil.getVersion(doc, label) ?? '';
  }
}

@Pipe({
  name: 'docDisplayName'
})
export class DocDisplayNamePipe implements PipeTransform {
  transform(doc: FolioDocument, label = false): string {
    return DocUtil.getDisplayName(doc, label) ?? '';
  }
}

@Pipe({
  name: 'documentDisplayId'
})
export class DocDisplayIdPipe implements PipeTransform {
  transform(doc: FolioDocument, label = false): string {
    return DocUtil.getDocumentDisplayId(doc, label) ?? '';
  }
}

@Pipe({
  name: 'docFileName'
})
export class DocFileNamePipe implements PipeTransform {
  transform(doc: FolioDocument, label = false): string {
    return DocUtil.getFileName(doc, label) ?? '';
  }
}

@Pipe({
  name: 'docTypeDisplay'
})
export class DocTypeDisplayPipe implements PipeTransform {
  transform(doc: FolioDocument, label = false): string {
    return DocUtil.getDocumentTypeDisplay(doc, label) ?? '';
  }
}

@Pipe({
  name: 'docFileUrl'
})
export class DocFileUrlPipe implements PipeTransform {
  transform(doc: FolioDocument, label = false): string {
    return DocUtil.getFileUrl(doc, label) ?? '';
  }
}

@Pipe({
  name: 'docDownloadUrl'
})
export class DocDownloadUrlPipe implements PipeTransform {
  transform(doc: FolioDocument, label = false): string {
    return DocUtil.getDownloadUrl(doc, label) ?? '';
  }
}

@Pipe({
  name: 'docCustomerLogo'
})
export class DocCustomerLogoPipe implements PipeTransform {
  transform(doc: FolioDocument, label = false): string {
    return DocUtil.getCustomerLogo(doc, label) ?? '';
  }
}

@Pipe({
  name: 'docImageUrl'
})
export class DocImageUrlPipe implements PipeTransform {
  transform(doc: FolioDocument, label = false): string {
    return DocUtil.hasImageRendition(doc) ?
      DocUtil.getRenditionURL(doc) ?? '' :
      DocUtil.getFileUrl(doc) ?? '';
  }
}

@Pipe({
  name: 'docThumbnailUrl'
})
export class DocThumbnailUrlPipe implements PipeTransform {
  transform(doc: FolioDocument, label = false): string {
    return DocUtil.getThumbnailUrl(doc, label) ?? '';
  }
}

@Pipe({
  name: 'docMimeType'
})
export class DocMimeTypePipe implements PipeTransform {
  transform(doc: FolioDocument, label = false): string {
    return DocUtil.getMimeType(doc, label) ?? '';
  }
}

@Pipe({
  name: 'docGeneralizedMimeType'
})
export class DocGeneralizedMimeTypePipe implements PipeTransform {
  transform(doc: FolioDocument): string {
    return DocUtil.getGeneralizedMimeType(doc) ?? '';
  }
}

@Pipe({
  name: 'docApprovalDate'
})
export class DocApprovalDatePipe implements PipeTransform {
  transform(doc: FolioDocument, label = false): string {
    return DocUtil.getApprovalDate(doc, label) ?? '';
  }
}

@Pipe({
  name: 'docExpirationDate'
})
export class DocExpirationDatePipe implements PipeTransform {
  transform(doc: FolioDocument, label = false): string {
    return DocUtil.getExpirationDate(doc, label) ?? '';
  }
}


@Pipe({
  name: 'docFileSize'
})
export class DocFileSizePipe implements PipeTransform {
  transform(bytes: number): string {
    return DataUtil.getHumanReadableFileSize(bytes) ?? '';
  }
}

@Pipe({
  name: 'docShareable'
})
export class DocShareable implements PipeTransform {
  transform(doc: FolioDocument, label = false): string {
    return DocUtil.getShareable(doc, label) ?? '';
  }
}

@Pipe({
  name: 'docIsShareable'
})
export class DocIsShareable implements PipeTransform {
  transform(doc: FolioDocument): boolean {
    return DocUtil.isShareable(doc);
  }
}

@Pipe({
  name: 'docNotShareableMessage'
})
export class DocNotShareableMessage implements PipeTransform {
  transform(doc: FolioDocument): string {
    return DocUtil.getNotShareableMessage(doc);
  }
}
