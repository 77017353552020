import { Component, forwardRef, Input } from '@angular/core';
import { NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormControlComponent } from '../abstract-form-control/abstract-form-control.component';


@Component({
  selector:    'flow-text-input',
  templateUrl: './text-input.component.html',
  providers:   [
    {
      provide:     NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseInputComponent),
      multi:       true,
    },
  ],
})
export class BaseInputComponent extends AbstractFormControlComponent<string> {
    @Input() minLength = 0;
    @Input() maxLength;
    @Input() area = false;

    characters: number = 0;

    ngAfterContentInit(): void {
      this.ngControl = this.injector.get(NgControl);
      this.characters = this.value?.length;
    }

    onChangeValue(event: any) {
      this.characters = event?.target?.value?.length;
      super.onChangeValue(event);
    }
}
