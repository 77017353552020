import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ld } from '@core';

@Injectable({
  providedIn: 'root',
})
export class FormInputControlService {
  public toFormGroup(fields: any): FormGroup {
    const group: any = {};
    ld.forEach(fields, (field, prop) => {
      const key     = field.key || prop;
      const control = field.constructor.name === 'FormArray' ? field : this.prepareControl(field);
      group[key]    = control;
    });
    return new FormGroup(group);
  }

  public validateFormField(form: any, field: any) {
    return form.controls[field.key].valid;
  }

  private prepareControl(field: any) {
    const control: any = new FormControl(field.value, field.validator);
    control.data       = {
      ...(field.data || {})
    };
    return control;
  }
}
