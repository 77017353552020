import { Component, OnInit } from '@angular/core';
import { FolioModalComponent } from '@shared/folio-modals/folio-modal/folio-modal.component';
import { ofType } from '@ngrx/effects';
import { FolioActions } from '@app/ngrx/actions';
import { filter } from 'rxjs/operators';
import { FolioModalType } from '@core/models/modals.model';
import { CollectionFolderItem, CollectionItem } from '@core/models';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector:    'folio-edit-collection-actions-modal',
  templateUrl: './edit-collection-actions-modal.component.html',
  styleUrls:   ['../../folio-modal-styles.less']
})
export class EditCollectionActionsModalComponent extends FolioModalComponent implements OnInit {

  public currentSelectedCollection: CollectionItem;
  public collectionParentId: string;
  public allFolderCollections: CollectionFolderItem[];

  ngOnInit(): void {
    this.actions$.pipe(
      ofType(FolioActions.toggleModal),
      filter(payload => payload.modalType === FolioModalType.EDIT_COLLECTION_ACTIONS)
    ).subscribe(({ show = true, data }) => {
      this.currentSelectedCollection = data.collection;
      this.collectionParentId        = data.collectionParentId;
      this.allFolderCollections      = data.allFolderCollections;
      this.toggle(show);
    });
  }

  handleEditPropertiesClick() {
    this.store.dispatch(FolioActions.toggleModal(
      {
        modalType: FolioModalType.UPDATE_COLLECTION_FORM,
        data:      {
          collection:           this.currentSelectedCollection,
          collectionParentId:   this.collectionParentId,
          allFolderCollections: this.allFolderCollections
        }
      }));
  }

  handleDeleteClick() {
    this.store.dispatch(FolioActions.toggleModal(
      {
        modalType: FolioModalType.DELETE_COLLECTION,
        data:      { collection: this.currentSelectedCollection }
      }));
  }
}
