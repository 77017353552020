import { AuditData, AuditRequest } from '../models/audit.model';
import { FolioDocument } from '../models';
import { DocUtil } from './doc.util';
import { UrlUtil } from './url.util';

export class AuditUtil {

  static getAuditRequest(event: string,
    doc: FolioDocument = null,
    data: AuditData = {},
    eventTimestamp: string | Date = new Date()): AuditRequest {
    if (eventTimestamp instanceof Date) {
      eventTimestamp = (eventTimestamp || new Date()).toISOString();
    }

    // add some basic supplemental data
    data = {
      ...data,
      tenant:    UrlUtil.getCustomer(),
      userAgent: window?.navigator?.userAgent,
      url:       window?.location?.href
    };

    // if there's a FolioDocument given, add even more data
    if (doc) {
      data = {
        ...data,
        documentId:        DocUtil.getId(doc),
        documentVersion:   DocUtil.getVersion(doc),
        documentDisplayId: DocUtil.getDocumentDisplayId(doc)
      };
    }
    return { application: 'folio-pwa', event, eventTimestamp, data };
  }
}
