import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FolioModalComponent } from '@shared/folio-modals/folio-modal/folio-modal.component';
import { FormGroup } from '@angular/forms';
import { ofType } from '@ngrx/effects';
import { FolioActions } from '@app/ngrx/actions';
import { filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { FolioModalType } from '@core/models/modals.model';
import {
  CollectionFolderItem,
  CollectionItem,
  CollectionItemType,
  CollectionVisibilityConfig,
  StaticListOption
} from '@core/models';
import { addCollection, updateCollection } from '@app/ngrx/collection/collection.actions';
import { Update } from '@ngrx/entity';
import { UntilDestroy } from '@ngneat/until-destroy';
import { select } from "@ngrx/store";
import * as FolioSelectors from "@app/ngrx/selectors";
import { of } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'folio-collection-form-modal',
  templateUrl: './collection-form-modal.component.html',
  styleUrls: ['./collection-form-modal.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class CollectionFormModalComponent extends FolioModalComponent implements OnInit {

  form: FormGroup;
  formType: 'new' | 'edit';
  currentSelectedCollection: CollectionItem;
  collectionParentId: string;
  allFolderCollections: CollectionFolderItem[];
  requestLoader: boolean;
  _show: boolean;
  userGroupOptions: StaticListOption[];
  visibilityConfig: CollectionVisibilityConfig;

  ngOnInit(): void {
    this.actions$.pipe(
      ofType(FolioActions.toggleModal),
      filter(payload =>
        (payload.modalType === FolioModalType.NEW_COLLECTION_FORM || payload.modalType === FolioModalType.UPDATE_COLLECTION_FORM)
      ),
      tap(({ modalType, show = true, data }) => {
        this._show = show;
        this.formType                  = modalType === FolioModalType.NEW_COLLECTION_FORM ? 'new' : 'edit';
        this.currentSelectedCollection = data.collection;
        this.collectionParentId        = data.collectionParentId;
        this.allFolderCollections      = data.allFolderCollections;
      }),
      withLatestFrom(this.store.pipe(select(FolioSelectors.selectCollectionVisibilityConfig))),
      switchMap(([_, visibilityConfig]) => {
        this.visibilityConfig = visibilityConfig
        if (visibilityConfig?.enabled) {
          return this.collectionsService.getUserGroupOptionsList();
        } else {
          return of(null);
        }
      })
    ).subscribe((userGroupOptions: StaticListOption[]) => {
      this.userGroupOptions = userGroupOptions;
      this.initForm();
      this.toggle(this._show);
    });
  }

  public initForm() {
    if (this.formType === 'new') {
      this.form = this.collectionsService.generateFormGroup(
        this.allFolderCollections,
        this.collectionParentId,
        'new',
        null,
        this.userGroupOptions
      );
    } else {
      this.form = this.collectionsService.generateFormGroup(
        this.allFolderCollections,
        this.collectionParentId,
        'edit',
        this.currentSelectedCollection,
        this.userGroupOptions
      );
    }

    setTimeout(() => {
      $(this.el.nativeElement).find('.tooltip').popup({
        position: 'top left',
        distanceAway: 1,
        inline: true
      });
    }, 500);
  }

  public handleSubmission() {
    this.form.markAllAsTouched();

    if (!this.form.invalid) {
      if (this.formType === 'new') {
        this.createCollection();
      } else {
        this.updateCollection();
      }
    }

    return false;
  }

  // create collection
  // -----------------------------------------------------------------------------------------------
  private createCollection() {
    const formValue     = this.form.value;
    const newCollection = {
      ... formValue,
      parentId: formValue.parentId,
      documentIds: [],
      forUserGroups: formValue.forUserGroups || []
    };

    this.requestLoader = true;
    this.collectionsService.createCollection(newCollection)
      .subscribe((collection: CollectionItem) => {
        this.requestLoader = false;
        this.hide();
        this.store.dispatch(addCollection(
          { collection: { ...collection, type: CollectionItemType.FOLDER, justCreated: true } }
        ));
      });
  }

  // update collection
  // -----------------------------------------------------------------------------------------------
  private updateCollection() {
    const formValue         = this.form.value;
    const updatedCollection = { ... formValue, parentId: formValue.parentId ? formValue.parentId : null };

    this.requestLoader = true;
    this.collectionsService.updateCollection(updatedCollection)
      .subscribe((collection: CollectionItem) => {
        this.requestLoader = false;
        this.hide();
        const updatedCollection: Update<CollectionFolderItem> = {
          id:      collection.id,
          changes: collection
        };
        this.store.dispatch(updateCollection({ collection: updatedCollection }));
      });
  }
}
