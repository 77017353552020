<ng-container *ngIf="!!document">
    <i class="close icon"></i>
    <ng-container *ngIf="!folioPublicDocumentLink; else hasPublicDocumentLink">
        <div class="header">
            <span>Get a link</span>
        </div>
        <div class="content">
            <div #emailForm class="ui form">
                <div class="to field">
                    <p style="margin-bottom: 2em">To share content with someone outside of Pepper Folio, generate a unique link. Then, you can email the link to the intended recipient(s) outside the system. Link views will be tied to the email address(es) you enter below.</p>
                    <label>This link will be sent to the following email address(es):</label>
                    <div class="ui action input">
                        <input placeholder="username@example.com" type="text" class="input" name="commaSeparatedEmails" #commaSeparatedEmails>
                        <button class="ui basic info icon button"
                                data-tooltip="Enter the email addresses for people you plan to share with. If you would like to send to multiple people, separate their emails with commas.">
                            <i class="info icon circle teal"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui basic primary button" (click)="handleCloseClick()">Cancel</button>
            <button class="ui teal submit button" (click)="handleGenerateLinkClick()">Generate link</button>
        </div>
    </ng-container>
    <ng-template #hasPublicDocumentLink>
        <div class="header">
            <span><i class="ui check icon" style="font-size:16px;"></i> Link created</span>
        </div>
        <div class="content">
            <div class="ui form">
                <div class="url field">
                    <label>Your link:</label>
                    <div class="ui action input">
                        <input type="text" readonly value="{{publicUrl}}" #urlCopyTarget>
                        <button class="ui teal button" *ngIf="!copied" (click)="handleCopyClick()">Copy</button>
                        <button class="ui teal button" *ngIf="copied">
                            <i class="check icon" style="margin-right: .5em;"></i> Copied
                        </button>
                    </div>
                </div>
                <div class="associated field">
                    <label>This link will be associated with:</label>
                    <ul style="margin: 0 0 24px;padding-left: 30px;">
                        <li *ngFor="let toEmail of folioPublicDocumentLink.to">
                            {{toEmail | truncate: 50}}
                        </li>
                    </ul>
                    <p *ngIf="(linkExpireAfterDays$ | async) as expireAfterDays">
                        This link will expire in {{expireAfterDays}} days, at which point the recipient will no longer
                        be able to access the shared content. However, you will be able to reactivate the link in
                        <a [routerLink]="['/shares']">Share History</a>.
                    </p>

                </div>
            </div>
            <folio-icon-message *ngIf="showLinkAlreadyExistsMessage" [messageClasses]="'info'" [iconClasses]="'info circle'"
                                style="margin-top: 24px;">
                <p>
                    You shared this content with this person or group of people in the past.
                    As a result, in your <a [routerLink]="['/shares']">Share History</a>, any new views of
                    this share will be grouped with the previous share.
                </p>
            </folio-icon-message>
        </div>
        <div class="link actions">
            <a (click)="handleCloseClick()">Close</a>
        </div>
    </ng-template>
</ng-container>
