import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {

  transform(text: string, length = 15): any {
    if (text.length > length) {
      return text.substring(0, length).trim() + '...';
    } else {
      return text;
    }
  }
}
