import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FolioState } from '../../ngrx/store';
import { select, Store } from '@ngrx/store';
import { canUserShareContent } from '../../ngrx/selectors';
import { delayWhen, filter, map, withLatestFrom } from 'rxjs/operators';
import * as FolioSelectors from '../../ngrx/selectors';

@Injectable({
  providedIn: 'root'
})
export class SharesGuard implements CanActivate {

  constructor(private router: Router, private store: Store<FolioState>) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      delayWhen(() => this.store.pipe(
        select(FolioSelectors.isConfigLoaded),
        filter(isConfigLoaded => isConfigLoaded))
      ),
      withLatestFrom(this.store.pipe(select(canUserShareContent))),
      map(([_, canUserShareContent]) => {
        if (canUserShareContent) {
          return true;
        } else {
          this.router.navigate(['/home']);
          return false;
        }
      }));
  }
}
