import { encode, decode } from 'js-base64';

export class UrlUtil {

  static encode(object: any): string {
    return encode(JSON.stringify(object));
  }

  static decode(str: string): any {
    return JSON.parse(decode(str));
  }

  // ---------------------------------------------------------------------------------------------------------------------------------------

  static folioServerUrl(uri: string, params: { [s: string]: string } = {}): string {
    return UrlUtil.url(UrlUtil.getFolioUrl(), uri, params);
  }

  static url(base: string, uri: string, params: { [s: string]: string } = {}): string {
    return `${base}${uri}${UrlUtil.createQueryString(params)}`;
  }

  static createQueryString(params: { [s: string]: string }): string {
    const qString = Object.entries(params).map(([k, v]) => `${k}=${v}`).join('&');
    if (qString !== '') {
      return '?' + qString;
    } else {
      return '';
    }
  }

  // ---------------------------------------------------------------------------------------------------------------------------------------

  static baseUrl(): string {
    return window.location.host;
  }

  static isLocal(): boolean {
    return UrlUtil.isLocalhost() || UrlUtil.isFolioLocal();
  }

  static isLocalhost(): boolean {
    return /localhost/.test(UrlUtil.baseUrl());
  }

  static isFolioLocal(): boolean {
    return /folio\.local/.test(UrlUtil.baseUrl());
  }

  // ---------------------------------------------------------------------------------------------------------------------------------------

  static PUBLICLY_ACCESSIBLE_PAGES = ['/shared', '/error/403', '/error/404', '/error/500'];

  static isPubliclyAccessiblyPage(): boolean {
    return UrlUtil.PUBLICLY_ACCESSIBLE_PAGES.some(pap => window?.location?.pathname.startsWith(pap));
  }

  static isPublicDocumentLinkPage(): boolean {
    return window?.location?.pathname.startsWith('/shared');
  }

  // ---------------------------------------------------------------------------------------------------------------------------------------

  static getFolioUrl() {
    if (UrlUtil.isFolioLocal()) {
      return 'https://dev-vodori.folio.local.vodori.net';
    } else {
      const customer      = UrlUtil.getCustomer();
      const clusterPrefix = UrlUtil.getClusterPrefix();
      return `https://${customer}.${clusterPrefix}folio.pepperapps.io`;
    }
  }

  static getCdnUrl(): string {
    const clusterPrefix = UrlUtil.getClusterPrefix();
    return `https://${clusterPrefix}cdn.pepperapps.io`;
  }

  // ---------------------------------------------------------------------------------------------------------------------------------------

  static getCustomer(): string {
    if (UrlUtil.isLocal()) {
      return UrlUtil.isLocalhost() ? 'vodori' : 'dev-vodori';
    } else {
      return window.location.hostname.split('.')[0];
    }
  }

  static getHostingEnvironment(): 'dev' | 'val' | 'prod' {
    const app = window.location.hostname.split('.')[1];
    if (UrlUtil.isLocal() || app.startsWith('dev-')) {
      return 'dev';
    } else if (app.startsWith('val-')) {
      return 'val';
    } else {
      return 'prod';
    }
  }

  static getClusterPrefix(): string {
    const hostEnv = UrlUtil.getHostingEnvironment();
    return hostEnv === 'prod' ? '' : `${hostEnv}-`;
  }
}
