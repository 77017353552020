export const ERROR_COMPONENT_PATHS = {
  403:          ['error', '403'],
  404:          ['error', '404'],
  500:          ['error', '500'],
  'shared-404': ['error', 'shared-404']
};

export interface ErrorModalData {
  title?: string;
  message?: string;
  noRefresh?: boolean;
}
