import { Component, OnInit } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { FolioActions } from '@app/ngrx/actions';
import { filter } from 'rxjs/operators';
import { FolioModalType } from '@core/models/modals.model';
import { FolioModalComponent } from '@shared/folio-modals/folio-modal/folio-modal.component';
import { CollectionItem } from '@core/models';
import { removeCollection } from '@app/ngrx/collection/collection.actions';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector:    'folio-delete-collection-modal',
  templateUrl: './delete-collection-modal.component.html'
})
export class DeleteCollectionModalComponent extends FolioModalComponent implements OnInit {

  public collection: CollectionItem;

  ngOnInit(): void {
    this.actions$.pipe(
      ofType(FolioActions.toggleModal),
      filter(payload => payload.modalType === FolioModalType.DELETE_COLLECTION)
    ).subscribe(({ show = true, data }) => {
      this.collection = data.collection;
      this.toggle(show);
    });
  }

  handleDeleteCollectionClick() {
    this.collectionsService.removeCollectionAndAllChildren(this.collection.id)
      .subscribe(() => {
        this.store.dispatch(removeCollection({ id: this.collection.id }));
      });
  }
}
