import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { AbstractFormControlComponent } from '../abstract-form-control/abstract-form-control.component';

@Component({
  selector:    'flow-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  providers:   [
    {
      provide:     NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxInputComponent),
      multi:       true,
    },
  ],
})
export class CheckboxInputComponent extends AbstractFormControlComponent<boolean> {
  ngAfterContentInit(): void {
    this.ngControl = this.injector.get(NgControl);
    ($('.ui.checkbox', this.$element) as any).checkbox({
      onChange: () => {
        this.value = !this.value;
        this.onChange(this.value);
      },
    });
  }
}














