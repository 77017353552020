import { DocumentProperty, FolioDocument, LabelValueType, PropertyType, ViewerType } from '../models';
import { DataUtil } from './data.util';
import { Const } from './const';

export class DocUtil {

  static MESSAGES = {
    NOT_SHAREABLE:                       'This document is not shareable because sharing is restricted by your company for this type of content.',
    NOT_SHAREABLE_BECAUSE_TYPE:          'This document is not shareable because the file type cannot be displayed in a browser.',
    CANT_REACTIVATE_SHARE:               'This link cannot be reactivated',
    NO_LONGER_SHAREABLE:                 'This document is no longer shareable because sharing is now restricted by your company for this type of content.',
    NO_LONGER_SHAREABLE_BECAUSE_EXPIRED: 'This document is no longer shareable because it has expired.',
    NO_LONGER_SHAREABLE_BECAUSE_TYPE:    'This document is no longer shareable because the current file type cannot be displayed in a browser.'
  };

  // Document property getters
  // ----------------------------------------------------

  static getId(doc: FolioDocument, label = false): string {
    return label
      ? <string> doc?.systemMetadata?.id?.label
      : <string> doc?.systemMetadata?.id?.value;
  }

  static getVersion(doc: FolioDocument, label = false): string {
    return label
      ? <string> doc?.publicMetadata?.version?.label
      : <string> doc?.publicMetadata?.version?.value;
  }

  static getDisplayName(doc: FolioDocument, label = false): string {
    return label
      ? <string> doc?.publicMetadata?.displayName?.label
      : <string> doc?.publicMetadata?.displayName?.value;
  }

  static getDocumentDisplayId(doc: FolioDocument, label = false): string {
    return label
      ? <string> doc?.publicMetadata?.documentDisplayId?.label
      : <string> doc?.publicMetadata?.documentDisplayId?.value;
  }

  static getFileName(doc: FolioDocument, label = false): string {
    return label
      ? <string> doc?.publicMetadata?.filename?.label
      : <string> doc?.publicMetadata?.filename?.value;
  }

  static getFileUrl(doc: FolioDocument, label = false): string {
    return label
      ? <string> doc?.systemMetadata?.fileUrl?.label
      : <string> doc?.systemMetadata?.fileUrl?.value;
  }

  static getDownloadUrl(doc: FolioDocument, label = false): string {
    return label
      ? <string> doc?.systemMetadata?.downloadUrl?.label
      : <string> doc?.systemMetadata?.downloadUrl?.value;
  }

  // for the public viewer the customerLogo is placed on the doc since we can't leverage the /config endpoint
  // without an authentication
  static getCustomerLogo(doc: FolioDocument, label = false): string {
    return label
      ? <string> doc?.systemMetadata?.customerLogo?.label
      : <string> doc?.systemMetadata?.customerLogo?.value;
  }

  static getDocumentTypeDisplay(doc: FolioDocument, label = false): string {
    return label
      ? <string> doc?.systemMetadata?.documentTypeDisplay?.label
      : <string> doc?.systemMetadata?.documentTypeDisplay?.value;
  }

  static getMimeType(doc: FolioDocument, label = false): string {
    return label
      ? <string> doc?.systemMetadata?.mimeType?.label
      : <string> doc?.systemMetadata?.mimeType?.value;
  }

  static getThumbnailUrl(doc: FolioDocument, label = false): string {
    return label
      ? <string> doc?.systemMetadata?.thumbnailUrl?.label
      : <string> doc?.systemMetadata?.thumbnailUrl?.value;
  }

  static getRenditionURL(doc: FolioDocument, label = false): string {
    return label
      ? <string> doc?.systemMetadata?.renditionUrl?.label
      : <string> doc?.systemMetadata?.renditionUrl?.value;
  }

  static getRenditionMimeType(doc: FolioDocument, label = false): string {
    return label
      ? <string> doc?.systemMetadata?.renditionMimeType?.label
      : <string> doc?.systemMetadata?.renditionMimeType?.value;
  }

  static getApprovalDate(doc: FolioDocument, label = false): string {
    return label
      ? <string> doc?.systemMetadata?.stateDate?.label
      : <string> doc?.systemMetadata?.stateDate?.value;
  }

  static getExpirationDate(doc: FolioDocument, label = false): string {
    return label
      ? <string> doc?.publicMetadata?.expirationDate?.label
      : <string> doc?.publicMetadata?.expirationDate?.value;
  }

  static getShareable(doc: FolioDocument, label = false): string {
    return label
      ? <string> doc?.systemMetadata?.shareable?.label
      : <string> doc?.systemMetadata?.shareable?.value;
  }

  // ----------------------------------------------------

  static getViewerType(doc: FolioDocument): ViewerType {
    if (DocUtil.isPdfDocument(doc) || DocUtil.hasPdfRendition(doc)) {
      return ViewerType.PDF;
    } else if (DocUtil.isImageDocument(doc) || DocUtil.hasImageRendition(doc)) {
      return ViewerType.IMAGE;
    } else if (DocUtil.isVideoDocument(doc)) {
      return ViewerType.VIDEO;
    } else if (DocUtil.isHtml5Document(doc)) {
      return ViewerType.HTML5;
    } else {
      return ViewerType.UNSUPPORTED;
    }
  }

  static isShareable(doc: FolioDocument): boolean {
    return DocUtil.isShareableByProperties(doc) && DocUtil.isShareableByType(doc);
  }

  static isShareableByProperties(doc: FolioDocument): boolean {
    return Boolean(DocUtil.getShareable(doc));
  }

  static isShareableByType(doc: FolioDocument): boolean {
    return DocUtil.isSupportedViewerFileType(doc);
  }

  static getNotShareableMessage(doc: FolioDocument): string {
    if (!DocUtil.isShareable(doc)) {
      if (!DocUtil.isShareableByProperties(doc)) {
        return DocUtil.MESSAGES.NOT_SHAREABLE;
      } else if (!DocUtil.isShareableByType(doc)) {
        return DocUtil.MESSAGES.NOT_SHAREABLE_BECAUSE_TYPE;
      } else {
        return DocUtil.MESSAGES.NOT_SHAREABLE;
      }
    }

    return null;
  }

  static getNoLongerShareableMessage(doc: FolioDocument): string {
    if (!DocUtil.isShareable(doc)) {
      if (!DocUtil.isShareableByProperties(doc)) {
        return DocUtil.MESSAGES.NO_LONGER_SHAREABLE;
      } else if (!DocUtil.isShareableByType(doc)) {
        return DocUtil.MESSAGES.NO_LONGER_SHAREABLE_BECAUSE_TYPE;
      } else {
        return DocUtil.MESSAGES.NO_LONGER_SHAREABLE;
      }
    }

    return null;
  }

  static isSupportedViewerFileType(doc: FolioDocument): boolean {
    return DocUtil.getViewerType(doc) !== ViewerType.UNSUPPORTED;
  }

  private static mimeMatches(doc: FolioDocument, mimes: Set<string>): boolean {
    return (mimes || new Set()).has(DocUtil.getMimeType(doc));
  }

  static isPdfDocument(doc: FolioDocument): boolean {
    return DocUtil.mimeMatches(doc, Const.VIEWER_PDF_TYPES);
  }

  static isImageDocument(doc: FolioDocument): boolean {
    return DocUtil.mimeMatches(doc, Const.VIEWER_IMAGE_TYPES);
  }

  static isVideoDocument(doc: FolioDocument): boolean {
    return DocUtil.mimeMatches(doc, Const.VIEWER_VIDEO_TYPES);
  }

  static isHtml5Document(doc: FolioDocument): boolean {
    return DocUtil.mimeMatches(doc, Const.VIEWER_HTML5_TYPES);
  }

  static getGeneralizedMimeType(doc: FolioDocument): string | null {
    const mime = DocUtil.getMimeType(doc);
    return Const.EXACT_MIME_LOOKUP[mime] || Const.GENERAL_MIME_LOOKUP[(mime || '').split('/')[0]] || mime;
  }

  static getLabelValueType(folioDocument: FolioDocument, prop: DocumentProperty): LabelValueType | null {
    let lv;
    if (prop.key.startsWith('fields.')) {
      if (prop.key.startsWith('fields.data.')) {
        lv = folioDocument.customerMetadata[prop.key.split('.').pop()];
      } else {
        lv = folioDocument.publicMetadata[prop.key.split('.').pop()];
      }
    } else if (prop.key.startsWith('sys.')) {
      lv = folioDocument.systemMetadata[prop.key.split('.').pop()];
    }

    if (lv) {
      return {
        label:            prop.overrideLabel || lv.label || prop.fallbackLabel,
        value:            lv.value,
        type:             PropertyType[prop.typeHint?.toUpperCase()] || DocUtil.getPropertyType(lv.value),
        isExpirationDate: prop.key === 'fields.expirationDate'
      };
    } else {
      return null;
    }
  }

  static isLongValue(value: string | string[]): boolean {
    const str = DataUtil.isArray(value) ? (<string[]> value).join(', ') : value;
    return str?.length > Const.LONG_VALUE_CHARACTER_COUNT;
  }

  static getPropertyType(value: any): PropertyType {
    if (DataUtil.isArray(value)) {
      return PropertyType.ARRAY;
    } else if (DataUtil.isDateString(value) || value instanceof Date) {
      return PropertyType.DATE;
    } else if (typeof value === 'string') {
      return PropertyType.STRING;
    } else {
      return PropertyType.UNKNOWN;
    }
  }

  static getPdfDocumentUrl(doc: FolioDocument): string {
    if (DocUtil.isPdfDocument(doc)) {
      return DocUtil.getFileUrl(doc);
    } else if (DocUtil.hasPdfRendition(doc)) {
      return DocUtil.getRenditionURL(doc);
    } else {
      return null;
    }
  }

  static hasPdfRendition(doc: FolioDocument): boolean {
    return !!DocUtil.getRenditionURL(doc) && DocUtil.getRenditionMimeType(doc) === 'application/pdf';
  }

  public static hasImageRendition(doc: FolioDocument): boolean {
    return !!DocUtil.getRenditionURL(doc) && DocUtil.getRenditionMimeType(doc) === 'image/png';
  }

  static addToDocumentIndex(indexedDocuments: { [s: string]: FolioDocument }, documentsToIndex: FolioDocument[] = []): void {
    documentsToIndex.forEach(folioDocument => {
      indexedDocuments[DocUtil.getId(folioDocument)] = folioDocument;
    });
  }

  static addToDocumentNameIndex(indexedDocumentNames: { [s: string]: string }, documentsToIndex: FolioDocument[] = []): void {
    documentsToIndex.forEach(folioDocument => {
      indexedDocumentNames[DocUtil.getId(folioDocument)] = DocUtil.getDisplayName(folioDocument);
    });
  }
}
