import { Component, ChangeDetectionStrategy, Injector, Input, ElementRef } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ld } from '@core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  template:        '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class AbstractFormControlComponent<T = any> implements ControlValueAccessor {
    @Input() label? = '';
    @Input() placeholder? = '';
    @Input() tooltip? = '';

    public id = ld.uniqueId('form-name-'+uuidv4());

    public value: T;
    public ngControl: NgControl;
    public disabled: boolean;
    public $element: HTMLElement;

    constructor(
        public readonly injector: Injector,
        private elRef: ElementRef,
    ) {
      this.$element = this.elRef.nativeElement;
    }

    public onChangeValue(event: any): void {
      const value = event.target.value;
      this.onChange(value);
      this.onTouched();
    }

    writeValue(value: never): void {
      this.value = value;
    }

    registerOnChange(fn: never): void {
      this.onChange = fn;
    }

    registerOnTouched(fn: never): void {
      this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
      this.disabled = isDisabled;
    }

    onChange: (value: T) => {};
    onTouched: () => {};

    get isValid() {
      return this.ngControl.valid;
    }

    get isTouched() {
      return this.ngControl.touched;
    }

    get isRequired() {
      const control = this.ngControl.control;
      if (control.validator === null) {
        return false;
      }
      return ld.get(control.validator(control), 'required');
    }
}
