import { EntityState } from '@ngrx/entity';
import { FetchProgress } from '@app/ngrx/store';
import { FolioDocument } from "@core/models/document.model";
import { StaticListOption } from "@core/models/config.model";

// ---------------------------------------------------------
// Collection ngrx store model
// ---------------------------------------------------------

export interface CollectionsState extends EntityState<CollectionItem> {
  status: FetchProgress;
  error: string | null;
  parentId: string;
  layout?: CollectionsLayout;
  sortOrder?: CollectionsSortOrder;
  list?: CollectionItem[];
  visibilityOptions?: StaticListOption[];
}

export enum CollectionsLayout {
  CARD_VIEW = 'card-view',
  LIST_VIEW = 'list-view'
}

export enum CollectionsSortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

// ---------------------------------------------------------
// Collection actions
// ---------------------------------------------------------

export interface LoadCollectionsAction {
  needRefresh?: boolean;
}

export interface LoadCollectionsSuccessAction {
  items: CollectionItem[];
  parentId: string;
  status?: FetchProgress;
  layout?: CollectionsLayout;
  sortOrder?: CollectionsSortOrder;
}

export interface LoadCollectionFailedAction {
  error: string;
}

export interface UpdateCollectionsViewAction {
  parentId: string;
  layout?: CollectionsLayout;
  sortOrder?: CollectionsSortOrder;
}

// ---------------------------------------------------------
// Collection items (folders or documents)
// ---------------------------------------------------------

export interface BaseCollectionItem {
  id: string;
  name: string;
  type: CollectionItemType;
  parentId: null | string;
  description?: string;
  justCreated?: boolean;
  documentIds?: string[];
}

export enum CollectionItemType {
  FOLDER = 'FOLDER',
  DOCUMENT = 'DOCUMENT'
}

export interface CollectionFolderItem extends BaseCollectionItem {
  type: CollectionItemType;
  icon: string;
  color?: string;
  promote?: boolean;
  lastPromotedDate?: string;
}

export interface CollectionDocumentItem extends BaseCollectionItem, FolioDocument {

}

export type CollectionItem = CollectionFolderItem | CollectionDocumentItem;
