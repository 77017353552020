import { Component, forwardRef, Input } from '@angular/core';
import { NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ld } from '@core';
import { AbstractFormControlComponent } from '../abstract-form-control/abstract-form-control.component';

@Component({
  selector:    'flow-select-input',
  templateUrl: './select-input.component.html',
  styleUrls:   ['./select-input.component.less'],
  providers:   [
    {
      provide:     NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInputComponent),
      multi:       true,
    }
  ],
})
export class SelectInputComponent extends AbstractFormControlComponent<string> {
    @Input() options: {name: string; id: string | number; icon: string}[];
    public initSelected: {name: string; id: string | number; icon: string};

    ngAfterContentInit(): void {
      const $dropdown = $('.ui.dropdown', this.$element);
      this.ngControl  = this.injector.get(NgControl);
      this.options    = this.options || ld.get(this.ngControl, 'control.data.options');

      this.initSelected = ld.find(this.options, { id: this.value });

      $dropdown.dropdown({
        showOnFocus:    false,
        fullTextSearch: 'exact',
        match:          'text',
        onChange:       selected => {
          this.onChange(selected);
        }
      });

      if (this.initSelected) {
        setTimeout(() => {
          // fomantic issue. won't set value if not in a timeout
          $dropdown.dropdown('set selected', this.initSelected.id);
        });
      }
    }
}
