import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector:    'folio-loader',
  templateUrl: './loader.component.html',
  styleUrls:   ['./loader.component.less']
})
export class LoaderComponent implements OnInit {

  @Input()
    inverted = true;

  constructor() {
  }

  ngOnInit(): void {
  }

}
