import { Injectable } from '@angular/core';
import { FolioDocument } from '@core/models';
import { BehaviorSubject, Subject } from 'rxjs';
import { ErrorModalData } from '../models/error.model';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  public errorModalData: Subject<ErrorModalData>;

  public documentForShareModal: BehaviorSubject<string>;
  public documentForActionsModal: BehaviorSubject<{folioDocument: FolioDocument; collectionId: string | null}>;
  public documentForDetailsModal: BehaviorSubject<FolioDocument>;

  public pdfNextPage: Subject<boolean>;
  public pdfPrevPage: Subject<boolean>;
  public pdfPageJump: Subject<number>;

  constructor() {
    this.errorModalData = new Subject<any>();

    this.documentForShareModal   = new BehaviorSubject(null);
    this.documentForActionsModal = new BehaviorSubject(null);
    this.documentForDetailsModal = new BehaviorSubject(null);

    this.pdfNextPage = new Subject();
    this.pdfPrevPage = new Subject();
    this.pdfPageJump = new Subject();
  }

  public emitDataForErrorModal(data: ErrorModalData) {
    this.errorModalData.next(data);
  }

  public emitDocumentForShareModal(docId: string) {
    this.documentForShareModal.next(docId);
  }

  public emitDocumentForActionsModal(folioDocument: FolioDocument, collectionId?: string) {
    this.documentForActionsModal.next({ folioDocument, collectionId: collectionId || null });
  }

  public emitDocumentForDetailsModal(folioDocument: FolioDocument) {
    this.documentForDetailsModal.next(folioDocument);
  }

  public emitPdfNextPage(val: boolean) {
    this.pdfNextPage.next(val);
  }

  public emitPdfPrevPage(val: boolean) {
    this.pdfPrevPage.next(val);
  }

  public emitPdfPageJump(page: number) {
    this.pdfPageJump.next(page);
  }

  public clearDocumentModals() {
    this.emitDocumentForShareModal(null);
    this.emitDocumentForDetailsModal(null);
    this.emitDocumentForActionsModal(null);
  }

  public showError(message: string, displayTime?: number) {
    this.showToast(message, 'error', displayTime);
  }

  public showInfo(message: string, displayTime?: number) {
    this.showToast(message, '', displayTime);
  }

  public showSuccess(message: string, displayTime?: number) {
    this.showToast(message, 'success', displayTime);
  }

  public showWarning(message: string, displayTime?: number) {
    this.showToast(message, 'warning', displayTime);
  }

  private showToast(message: string, type: string, displayTime = 5000): void {
    ($('body') as any).toast({
      class:     type,
      displayTime,
      message,
      closeIcon: true
    });
  }
}
