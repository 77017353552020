import * as Sentry from '@sentry/browser';
import { SeverityLevel, Event } from '@sentry/types';
import { SentryConfig } from './app.model';
import { environment } from '@env/environment';
import { UrlUtil } from './url.util';

export class SentryUtil {

  static IGNORE_ERROR_CODES = [401, 403, 404];

  /**
   * Initialize sentry
   */
  static initialize(sentryConfig: SentryConfig): void {
    if (!this.shouldLogToSentry()) {
      return;
    }

    const supportedBrowser = navigator?.userAgent?.includes('Chrome');
    Sentry.init({
      environment:   UrlUtil.getHostingEnvironment(),
      dsn:           sentryConfig.dsn,
      allowUrls: ['^https?:\\/\\/(.*)?pepperapps.io\\/?$'],
      ignoreErrors:  sentryConfig.ignoredErrors,
      beforeSend(event: Event) {
        if (supportedBrowser) {
          return event;
        } else {
          return null;
        }
      }
    });
  }

  /**
   * Send a logging message to Sentry
   */
  static sendSentryMessage(error: Error, severity: SeverityLevel = 'log') {
    if (!this.shouldLogToSentry() || !this.shouldLogThisErrorToSentry(error)) {
      return;
    }

    Sentry.captureMessage(JSON.stringify(error || {}), severity);
  }

  /**
   * Send an error exception to Sentry
   */
  static sendSentryException(error: any) {
    if (!this.shouldLogToSentry() || !this.shouldLogThisErrorToSentry(error)) {
      return;
    }

    Sentry.captureException(JSON.stringify(error || {}));
  }

  /**
   * Set the user on the current Sentry context
   */
  static setUser(user: any): void {
    if (!this.shouldLogToSentry()) {
      return;
    }

    Sentry.setUser({
      id:    user['http://pepperapps.io/uuid'] || user.id || user.email,
      email: user.email
    });
  }

  static shouldLogThisErrorToSentry(error: any) {
    return !this.IGNORE_ERROR_CODES.includes(error?.status);
  }

  static shouldLogToSentry(): boolean {
    return environment.production && !UrlUtil.isLocal();
  }
}
