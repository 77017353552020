<ng-container *ngIf="active">
  <div class="header">Edit actions</div>
  <div class="content">
    <div class="folio--modal-actions-list actions">
      <a class="ok" (click)="handleEditPropertiesClick()">
        Edit properties
      </a>
      <a class="ok" (click)="handleDeleteClick()">
        Delete
      </a>
      <a class="deny">
        Cancel
      </a>
    </div>
  </div>
</ng-container>