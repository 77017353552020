import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { FolioState } from '../../../../ngrx/store';
import { MessagingService } from '@core/services/messaging.service';
import { filter } from 'rxjs/operators';
import { ErrorModalData } from '@core/models/error.model';

@Component({
  selector:    'folio-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls:   ['./error-modal.component.less']
})
export class ErrorModalComponent implements OnInit, AfterViewInit, OnDestroy {

  subscription: Subscription;
  $modal: JQuery<Element>;

  errorData: ErrorModalData;

  constructor(private el: ElementRef,
              private store: Store<FolioState>,
              private messagingService: MessagingService) {
  }

  ngOnInit(): void {
    this.subscription =
      this.messagingService.errorModalData
        .pipe(
          filter(data => !!data),
        ).subscribe((data) => {
          this.errorData = data;
          this.$modal.modal('show');
        });
  }

  ngAfterViewInit() {
    this.$modal = $(this.el.nativeElement).modal({
      duration: 200,
      onHide:   () => {
        if (!this.errorData.noRefresh) {
          this.refreshPage();
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  handleRefreshClick() {
    this.refreshPage();
  }

  refreshPage() {
    window.location.reload();
  }

}
