export interface AuditRequest {
  event: string;
  eventTimestamp: string;
  application?: string;
  data?: AuditData;
}

export interface AuditData {
  [s: string]: any;
}

export const AuditActions = {
  VIEW_DOCUMENT:            'view_document',
  VIEW_DOCUMENT_PROPERTIES: 'view_document_properties',
  FAVORITE_DOCUMENT:        'favorite_document',
  UNFAVORITE_DOCUMENT:      'unfavorite_document',
  DOWNLOAD_DOCUMENT:        'download_document',
  VIEW_COLLECTION:          'collection_viewed'
};
