import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import { AppComponent } from './app.component';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { AuthConfigService } from './auth-config.service';
import { FolioErrorHandler } from '@core/handlers/folio-error.handler';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { environment } from '@env/environment';
import { CollectionStoreModule } from './ngrx/collection/collection-store.module';

function configInitializer(authConfigService: AuthConfigService) {
  return (): Promise<any> => authConfigService.load();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CollectionStoreModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    AuthModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide:  HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi:    true
    },
    {
      provide:    APP_INITIALIZER,
      useFactory: configInitializer,
      deps:       [AuthConfigService],
      multi:      true
    },
    {
      provide:  ErrorHandler,
      useClass: FolioErrorHandler
    },
    {
      provide:    SwRegistrationOptions,
      useFactory: () => ({ enabled: environment.production }),
    },
  ]
})
export class AppModule {
}
