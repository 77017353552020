export class Const {
  static readonly LONG_VALUE_CHARACTER_COUNT = 128;

  static readonly DAY_IN_MILLIS = 86400000;

  static readonly VIEWER_PDF_TYPES   = new Set(['application/pdf']);
  static readonly VIEWER_IMAGE_TYPES = new Set(['image/jpg', 'image/jpeg', 'image/png', 'image/gif']);
  static readonly VIEWER_VIDEO_TYPES = new Set(['video/mp4', 'video/ogg', 'video/webm']);
  static readonly VIEWER_HTML5_TYPES = new Set(['application/pepperapp']);

  static readonly EXACT_MIME_LOOKUP = {
    'application/pdf':                                                           'PDF',
    'application/xml':                                                           'XML',
    'application/zip':                                                           'ZIP',
    'application/x-zip':                                                         'ZIP',
    'application/x-zip-compressed':                                              'ZIP',
    'application/pepperapp':                                                     'Pepper App',
    'application/json':                                                          'JSON',
    'audio/mpeg':                                                                'MP3',
    'image/jpeg':                                                                'JPG',
    'image/gif':                                                                 'GIF',
    'image/tif':                                                                 'TIFF',
    'image/tiff':                                                                'TIFF',
    'image/png':                                                                 'PNG',
    'text/plain':                                                                'Text',
    'text/html':                                                                 'HTML',
    'application/vnd.ms-excel':                                                  'Excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':         'Excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template':      'Excel',
    'application/vnd.ms-excel.sheet.macroEnabled.12':                            'Excel',
    'application/vnd.ms-excel.template.macroEnabled.12':                         'Excel',
    'application/vnd.ms-excel.addin.macroEnabled.12':                            'Excel',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12':                     'Excel',
    'application/vnd.ms-powerpoint':                                             'Power Point',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'Power Point',
    'application/vnd.openxmlformats-officedocument.presentationml.template':     'Power Point',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow':    'Power Point',
    'application/vnd.ms-powerpoint.addin.macroEnabled.12':                       'Power Point',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12':                'Power Point',
    'application/vnd.ms-powerpoint.template.macroEnabled.12':                    'Power Point',
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':                   'Power Point',
    'application/msword':                                                        'Word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':   'Word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template':   'Word',
    'application/vnd.ms-word.document.macroEnabled.12':                          'Word',
    'application/vnd.ms-word.template.macroEnabled.12':                          'Word'
  };

  static readonly GENERAL_MIME_LOOKUP = {
    audio: 'Audio',
    video: 'Video',
    image: 'Image',
    text:  'Text',
  };

  static readonly QUERY_NAMES = {
    sortOrder: 'sortOrder',
    layout:    'layout',
    id:        'id',
  };
}

