import { Component, OnInit } from '@angular/core';
import { filter, first, tap, Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { CollectionFolderItem, CollectionItem } from '@core/models';
import { FormGroup } from '@angular/forms';
import { updateCollection } from '@app/ngrx/collection/collection.actions';
import * as FolioSelectors from '@app/ngrx/selectors';
import { getCollectionsList, getCollectionsState } from '@app/ngrx/collection/collection.reducer';
import { ld } from '@core';
import { Update } from '@ngrx/entity';
import { FolioActions } from '@app/ngrx/actions';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FolioModalComponent } from '@shared/folio-modals/folio-modal/folio-modal.component';
import { ofType } from '@ngrx/effects';
import { FolioModalType } from '@core/models/modals.model';
import { select } from '@ngrx/store';
import { bulkSelectCollectionId } from '@app/ngrx/selectors';

@UntilDestroy()
@Component({
  selector:    'folio-add-to-collection-modal',
  templateUrl: './add-to-collection-modal.component.html'
})
export class AddToCollectionModalComponent extends FolioModalComponent implements OnInit {

  public success = false;

  public requestLoader = false;
  public form: FormGroup;
  public selectedCollection: CollectionItem;
  public selectedDocumentsLength: number;
  public documentId: string;

  ngOnInit() {
    this.actions$.pipe(
      ofType(FolioActions.toggleModal),
      filter(payload => payload.modalType === FolioModalType.ADD_TO_COLLECTION),
      tap(() => this.success = false),
      withLatestFrom(
        this.store.pipe(select(getCollectionsList)),
        this.store.pipe(select(bulkSelectCollectionId))
      )
    ).subscribe(([{ show = true, data }, collections, collectionId]) => {
      this.createForm(collections, collectionId);
      this.documentId = data.documentId;
      this.toggle(show);
    });
  }

  public createForm(collections, collectionId?: string) {
    this.form = this.collectionsService.generateFormGroup(
      collections,
      collectionId || null,
      'add-to-collection'
    );
  }

  public handleAddToCollectionClick() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const formValue = this.form.value;
    const parentId  = formValue.parentId;

    this.requestLoader = true;
    this.store.select(getCollectionsState)
      .pipe(
        withLatestFrom(this.getDocIdsToAddToCollection()),
        switchMap(([collections, ids]) => {
          this.selectedDocumentsLength = ids.length;
          const current                = collections.entities[parentId];
          this.selectedCollection      = current;
          const documentIds            = ld.uniq(current.documentIds.concat(ids));
          const updatedCollection      = { ...current, documentIds };
          return this.collectionsService.updateCollection(updatedCollection);
        }),
        first()
      )
      .subscribe((collection: CollectionItem) => {
        const updatedCollection: Update<CollectionFolderItem> = {
          id:      collection.id,
          changes: collection
        };
        this.store.dispatch(updateCollection({ collection: updatedCollection }));
        this.store.dispatch(FolioActions.exitBulkSelect());
        this.requestLoader = false;
        this.success       = true;
      });

    return false;
  }

  public handleViewCollectionClick() {
    const queryParams = { id: this.selectedCollection.id };
    this.router.navigate(['/collections'], { queryParams });
  }

  private getDocIdsToAddToCollection(): Observable<string[]> {
    return this.documentId ?
      of([this.documentId]) : this.store.select(FolioSelectors.bulkSelectIds);
  }
}
