<ng-container *ngIf="active">
  <div class="header">Add</div>
  <div class="content">
    <div class="folio--modal-actions-list actions">
      <a class="ok" (click)="handleNewCollectionClick()">
        New collection
      </a>
      <a class="ok" (click)="handleContentFromLibraryClick()" *ngIf="!!collectionParentId">
        Content from Library
      </a>
      <a class="deny">
        Cancel
      </a>
    </div>
  </div>
</ng-container>