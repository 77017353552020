import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FolioConfiguration } from '../models';
import { ErrorService } from './error.service';
import { catchError, map } from 'rxjs/operators';
import { UrlUtil } from '../utils/url.util';

@Injectable()
export class ConfigService {

  constructor(private http: HttpClient, private errorService: ErrorService) {
  }

  getFolioConfig(): Observable<FolioConfiguration> {
    const url = UrlUtil.folioServerUrl('/api/v1/config');
    return this.http.get(url).pipe(
      map(config => <FolioConfiguration> config),
      catchError(this.errorService.logModalAndContinue<FolioConfiguration>())
    );
  }
}
