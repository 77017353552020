import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, first, forkJoin, map, Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { UrlUtil } from '../utils/url.util';
import { ErrorService } from './error.service';
import {
  BASE_COLLECTION_COUNT,
  CollectionFolderItem, CollectionItem,
  CollectionItemType,
  CollectionsLayout, CollectionsSortOrder, CollectionsState,
  FolioDocument,
  FolioDocumentRequest, StaticListOption, UpdateCollectionsViewAction
} from '../models';
import { FolioState } from '@app/ngrx/store';
import { select, Store } from '@ngrx/store';
import { selectQueryParams } from '@app/ngrx/selectors';
import { ld } from '..';
import { Const } from '../utils/const';
import {
  getCollectionsList,
  getCollectionsState,
  getVisibilityOptions
} from '@app/ngrx/collection/collection.reducer';
import { FormGroup, Validators } from '@angular/forms';
import { getAllChildren } from '@app/features/collections/helpers/breadcrumb.functions';
import { COLLECTION_ICONS, COLLECTION_COLORS } from '@app/features/collections/collections.constant';
import { FormInputControlService } from '@shared/form-inputs/services/form-input-control.service';
import { FilterUtil } from '@core/utils/filter.util';
import { DocumentService } from './document.service';
import { sortCollection } from '@core/utils/list.util';
import { DocUtil } from '@core/utils/doc.util';
import { tap } from "rxjs/operators";
import { setVisibilityOptions } from "@app/ngrx/collection/collection.actions";

@Injectable({
  providedIn: 'root'
})
export class CollectionsService {

  private readonly BASE_URL: string = UrlUtil.folioServerUrl('/api/v1/collections');

  constructor(private http: HttpClient,
    private store: Store<FolioState>,
    private errorService: ErrorService,
    private documentService: DocumentService,
    private formInputControlService: FormInputControlService
  ) { }

  public getCollections(): Observable<CollectionItem[]> {
    return this.http.get(this.BASE_URL).pipe(
      map((list) => {
        return ld.map(list, (item) => {
          return { ...item, type: CollectionItemType.FOLDER };
        });
      }),
      catchError(this.errorService.logModalAndContinue<CollectionsState>())
    );
  }

  public createCollection (collection: CollectionFolderItem): Observable<CollectionFolderItem> {
    if (collection.parentId === 'root') {
      delete collection.parentId;
    }
    delete collection.id; // used the same form creating end editing
    return this.http.post<CollectionFolderItem>(this.BASE_URL, collection);
  }

  public updateCollection (collection: CollectionItem): Observable<CollectionFolderItem> {
    const id = collection.id;
    delete collection.id;
    if (collection.parentId === 'root') {
      delete collection.parentId;
    }
    return this.http.put<CollectionFolderItem>(`${this.BASE_URL}/${id}`, collection);
  }

  public removeCollectionAndAllChildren (id: string) {
    return this.store.select(getCollectionsList)
      .pipe(
        first(),
        switchMap((collections: CollectionFolderItem[]) => {
          const currentWithChildren = [id].concat(getAllChildren(collections, id));
          return forkJoin(
            ld.map(currentWithChildren, (id) => this.deleteRequest(id))
          );
        })
      );
  }

  private deleteRequest(id: string) {
    return this.http.delete(`${this.BASE_URL}/${id}`);
  }

  public getUserGroupOptionsList(): Observable<StaticListOption[]> {
    return this.store.pipe(select(getVisibilityOptions)).pipe(
      switchMap((visibilityOptions) => {
        if (visibilityOptions) {
          return of(visibilityOptions);
        } else {
          return this.http.get(`${this.BASE_URL}/user-groups`).pipe(
            tap((options: StaticListOption[]) => {
              this.store.dispatch(setVisibilityOptions({ options }));
            }),
            catchError(this.errorService.logModalAndContinue<StaticListOption[]>())
          );
        }
      })
    )
  }

  // -----------------------------------------------------------------------------------------------


  public getSortAndLayoutOptions(): Observable<UpdateCollectionsViewAction> {
    return this.store.select(selectQueryParams)
      .pipe(
        withLatestFrom(this.store.select(getCollectionsState)),
        map(([inQueries, inStore]) => {
          let layout     = ld.get(inQueries, `${Const.QUERY_NAMES.layout}`) || ld.get(inStore, 'layout') || CollectionsLayout.CARD_VIEW;
          let sortOrder  = ld.get(inQueries, `${Const.QUERY_NAMES.sortOrder}`) || ld.get(inStore, 'sortOrder') || CollectionsSortOrder.ASC;
          const parentId = ld.get(inQueries, `${Const.QUERY_NAMES.id}`) || 'root';

          if (!Object.values(CollectionsLayout).includes(layout)) {
            layout = CollectionsLayout.CARD_VIEW;
          }

          if (!Object.values(CollectionsSortOrder).includes(sortOrder)) {
            sortOrder = CollectionsSortOrder.ASC;
          }

          return { layout, sortOrder, parentId };
        })
      );
  }

  public generateFormGroup(
    collectionsList: CollectionFolderItem[],
    parentId: string,
    modalMode: 'new' | 'edit' | 'add-to-collection' = 'new',
    currentValues?: CollectionItem,
    userGroupOptions?: StaticListOption[]
  ): FormGroup {
    // in the edit mode user can not move parent to children list
    let collections = collectionsList;
    if (modalMode === 'edit') {
      const children = getAllChildren(collections, currentValues.id);
      collections    = ld.filter(collectionsList, (item) => {
        return !children.includes(item.id) && item.id !== currentValues.id;
      });
    }

    const parentOptions = (modalMode === 'add-to-collection' ? [] :
      [{ id: 'root', name: 'Collection (default)', icon: 'folder grey' }])
      .concat(ld.map(collections, (item: CollectionFolderItem) => {
        return {
          id:   item.id,
          name: item.name,
          icon: `${item.icon} grey`
        };
      }));

    const formFields = {
      name: {
        value:     ld.get(currentValues, 'name') || '',
        validator: modalMode === 'add-to-collection' ? [] : [Validators.required]
      },
      description: {
        value:     ld.get(currentValues, 'description') || '',
        validator: []
      },
      documentIds: {
        value:     ld.get(currentValues, 'documentIds') || [],
        validator: []
      },
      id: {
        value:     ld.get(currentValues, 'id') || '',
        validator: []
      },
      promote: {
        value: ld.get(currentValues, 'promote') || false ,
      },
      icon: {
        value: ld.get(currentValues, 'icon') || 'folder' ,
        data:  {
          options: ld.map(COLLECTION_ICONS, (item) => {
            return { ...item, icon: 'grey' };
          }),
          required: true,
        },
        validator: [Validators.required]
      },
      color: {
        value: ld.get(currentValues, 'color') || 'grey' ,
        data:  {
          options: ld.map(COLLECTION_COLORS, (item) => {
            return { ...item, icon: 'circle' };
          }),
          required: true,
        },
        validator: [Validators.required]
      },
      forUserGroups: {
        value: ld.get(currentValues, 'forUserGroups'),
        data:  {
          options: ld.map(userGroupOptions, (option) => {
            return {
              name: option.display,
              id: option.value
            };
          })
        }
      },
      parentId: {
        value: ld.get(currentValues, 'parentId') || parentId,
        data:  {
          options:  sortCollection(parentOptions, true, 'name', CollectionsSortOrder.ASC),
          required: true,
        },
        validator: modalMode === 'add-to-collection' ? [Validators.required] : []
      },
    };

    return this.formInputControlService.toFormGroup(formFields);
  }

  /**
   *  This function can be used for getting sorted list with collections and related documents to parent
   *
   * @param parentId The id of the collection.
   * @param alreadyLoadedDocuments The loaded Folio documents
   */
  public getSortedCollectionsWithRelatedDocuments(parentId: string, alreadyLoadedDocuments?: FolioDocument[]): Observable<CollectionsState> {
    let collectionState;
    return this.store.select(getCollectionsState)
      .pipe(
        switchMap((res) => {
          collectionState   = res;
          const current     = res.entities[parentId];
          const documentIds = current?.documentIds;

          if (documentIds?.length === 0 || parentId === null) {
            return of({ folioDocuments: [] });
          }

          if (alreadyLoadedDocuments) {
            return of({ folioDocuments: alreadyLoadedDocuments });
          }

          const folioDocumentRequest: FolioDocumentRequest = new FolioDocumentRequest();
          delete folioDocumentRequest.folioFilters.freeText;
          folioDocumentRequest.folioPaging.count      = BASE_COLLECTION_COUNT;
          folioDocumentRequest.folioSort              = { attribute: 'sys.stateDate', descending: true };
          folioDocumentRequest.folioFilters.rsqlQuery = FilterUtil.in('id', documentIds);
          return this.documentService.queryFolioDocuments(folioDocumentRequest);
        }),
        map((docs) => {
          const onlyCollectionsForCurrentParent = ld.filter(ld.values(collectionState.entities), { parentId: parentId });
          const docIdsForCurrent                = collectionState.entities[parentId]?.documentIds || [];

          const sortOrder                         = ld.get(collectionState, 'sortOrder') || 'asc';
          const onlyDocumentsForCurrentCollection = ld.filter(docs?.folioDocuments, (doc: FolioDocument) => {
            return docIdsForCurrent.includes(DocUtil.getId(doc));
          });

          const folioDocuments = ld.map(onlyDocumentsForCurrentCollection, (item: FolioDocument) => {
            return {
              ...item,
              name: item.publicMetadata.displayName.value,
              id:   item.systemMetadata.id.value,
              type: CollectionItemType.DOCUMENT
            };
          });
          return {
            ...collectionState,
            list: sortCollection(onlyCollectionsForCurrentParent.concat(folioDocuments), true, 'name', sortOrder),
          };
        })
      );
  }
}
