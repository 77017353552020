import { PipeTransform, Pipe } from '@angular/core';
import { DataUtil } from '@core/utils/data.util';

@Pipe({ name: 'csv' })
export class CsvPipe implements PipeTransform {

  transform(value: string | string[], separator = ', '): any {
    if (!value) {
      return '';
    }
    return DataUtil.isArray(value) ? (<string[]> value).join(separator) : value;
  }
}
