<ng-container *ngIf="!!document">
  <i class="close icon"></i>
  <div class="header">
    <span>Actions</span>
  </div>
  <div class="content">
    <div class="folio--modal-actions-list">
      <a *ngIf="showViewContentLink" (click)="handleViewContentClick()">
        View content
      </a>
      <a *ngIf="(canUserFavorite$ | async)" (click)="handleFavoriteClick()">
        <i class="star icon {{(isFavorite$ | async) ? 'yellow' : 'outline'}}"></i> Favorite
      </a>
      <ng-container *ngIf="(canUserShare$ | async)">
        <ng-container *ngIf="(document | docIsShareable); else documentNotShareable">
          <a (click)="handleShareClick()">
            Share
          </a>
        </ng-container>
        <ng-template #documentNotShareable>
          <a class="disabled">
            <span>Share</span>
            <div class="ui basic info icon button"
                 [attr.data-tooltip]="(document | docNotShareableMessage)">
              <i class="question icon circle teal"></i>
            </div>
          </a>
        </ng-template>
      </ng-container>
      <a (click)="handleContentDetailsClick()">
        Content details
      </a>
      <a *ngIf="(canDownload$ | async)" (click)="handleDownloadClick()">
        Download
      </a>
      <a *ngIf="!isCollectionViewMode && (canUserCreateCollection$ | async)" (click)="handleAddToCollectionClick()">
        Add to collection
      </a>
      <a *ngIf="isCollectionViewMode && (canUserCreateCollection$ | async)" (click)="handleRemoveFromCollectionClick()">
        Remove from collection
      </a>
      <a (click)="handleCancelClick()">
        Cancel
      </a>
    </div>
  </div>
</ng-container>
