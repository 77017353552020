<div class="field" [ngClass]="{'required': isRequired, 'error': !isValid && isTouched}">
  <label>{{label}}</label>
  <div *ngIf="!!maxLength" class="char-count" [class.max]="characters == maxLength">
    {{this.characters}}/{{this.maxLength}}
  </div>
  <div class="ui input">
    <ng-container *ngIf="area else input">
        <textarea
          [name]="id"
          type="text"
          [minLength]="minLength"
          [maxLength]="maxLength || 10000"
          [placeholder]="placeholder"
          [value]="value"
          (input)="onChangeValue($event)"></textarea>
    </ng-container>
    <ng-template #input>
      <input
        [name]="id"
        type="text"
        [minLength]="minLength"
        [maxLength]="maxLength || 10000"
        [placeholder]="placeholder"
        [value]="value"
        (input)="onChangeValue($event)">
    </ng-template>
  </div>
</div>
