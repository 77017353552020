import { Component, forwardRef, Input } from '@angular/core';
import { NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ld } from '@core';
import { AbstractFormControlComponent } from '../abstract-form-control/abstract-form-control.component';

@Component({
  selector:    'flow-multi-select-input',
  templateUrl: './multi-select-input.component.html',
  styleUrls:   ['./multi-select-input.component.less'],
  providers:   [
    {
      provide:     NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectInputComponent),
      multi:       true,
    }
  ],
})
export class MultiSelectInputComponent extends AbstractFormControlComponent<string[]> {
  @Input() options: { name: string; id: string | number; }[];

  ngAfterContentInit(): void {
    const $dropdown = $('.ui.dropdown', this.$element);
    this.ngControl = this.injector.get(NgControl);
    this.options = this.options || ld.get(this.ngControl, 'control.data.options');

    $dropdown.dropdown({
      showOnFocus: false,
      placeholder: this.placeholder,
      fullTextSearch: 'exact',
      match: 'text',
      onChange: selected => {
        this.onChange(selected)
      }
    });

    if (this.value?.length) {
      setTimeout(() => {
        // fomantic issue. won't set value if not in a timeout
        $dropdown.dropdown('set selected', this.value);
      });
    }
  }
}
