<div class="field" [ngClass]="{'required': isRequired, 'error': !isValid && isTouched}">
    <div class="ui checkbox">
        <input type="checkbox"
               [attr.name]="id"
               [value]="value"
               [checked]="value === true"
               [disabled]="disabled"
        >
        <label [attr.for]="id">{{label}}</label>
    </div>
</div>
