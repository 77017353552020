import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigService } from './services/config.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { FolioEffects } from '../ngrx/effects';
import { CollectionStoreModule } from '../ngrx/collection/collection-store.module';

import * as folioReduction from '../ngrx/reducer';
import * as collectionsReducer from '../ngrx/collection/collection.reducer';


@NgModule({
  declarations: [],
  imports:      [
    CommonModule,
    HttpClientModule,
    CollectionStoreModule,
    StoreModule.forRoot({
      router:      routerReducer,
      folio:       folioReduction.reducer,
      collections: collectionsReducer.reducer
    }),
    StoreDevtoolsModule.instrument({
      maxAge:    25, // Retains last 25 states
      logOnly:   environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    EffectsModule.forRoot([FolioEffects]),
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [
    ConfigService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
  ]
})
export class CoreModule {
}
