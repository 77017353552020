import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector:    'folio-icon-message',
  templateUrl: './icon-message.component.html',
  styleUrls:   ['./icon-message.component.less']
})
export class IconMessageComponent implements OnInit {

  @Input()
    messageClasses: string;

  @Input()
    iconClasses: string;

  constructor() { }

  ngOnInit(): void {
  }

}
