<ng-container *ngIf="active">
  <div class="header">
    Confirm removal
  </div>
  <div class="content">
    <ng-container *ngIf="documentIds.length == 1 else multipleDocuments">
      <p>
        Are you sure you want to remove "{{documentName}}" from "{{selectedCollection.name}}"?
      </p>
      <p>
        Though this document will be removed from this collection, it will still be viewable in the Pepper Folio Library.
      </p>
    </ng-container>
    <ng-template #multipleDocuments>
      <p>
        Are you sure you want to remove {{documentIds.length}} documents from "{{selectedCollection.name}}"?
      </p>
      <p>
        Though individual documents can be removed from a collection, they will still be viewable in the Pepper Folio Library.
      </p>
    </ng-template>
  </div>
  <div class="actions">
    <div class="ui basic primary deny button">
      Cancel
    </div>
    <div class="ui teal submit button" (click)="handleConfirmClick()" [ngClass]="{'loading': requestLoader}">
      Remove
    </div>
  </div>
</ng-container>
