<ng-container *ngIf="!!document">
    <i class="close icon"></i>
    <div class="header">
        <span>Content details</span>
    </div>
    <div class="content">
        <div class="folio--tab-container" [ngClass]="{'no-sharing': !(sharingEnabled$ | async)}">
            <div class="ui top attached tabular menu">
                <div class="item" data-tab="properties">Properties</div>
                <div class="item" data-tab="metrics">Content usage metrics</div>
            </div>
            <!-- Content details -->
            <div class="ui bottom attached tab" data-tab="properties">
                <table class="ui very basic unstackable table" *ngIf="!!properties?.length">
                    <tbody>
                    <tr *ngFor="let prop of properties">
                        <th scope="row">
                            <h5>{{prop.label}}</h5>
                        </th>
                        <td>
                            <ng-container [ngSwitch]="prop.type">
                                <ng-container *ngSwitchCase="propertyType.STRING">
                                    <div class="break-spaces">{{prop.value}}</div>
                                </ng-container>
                                <ng-container *ngSwitchCase="propertyType.ARRAY">
                                    {{prop.value | csv}}
                                </ng-container>
                                <ng-container *ngSwitchCase="propertyType.DATE">
                                    <ng-container *ngIf="prop.isExpirationDate && (prop.value | withinDays); else standardDateDisplay">
                                    <span class="ui red quaternary label">
                                        <i class="exclamation triangle icon" style="margin-right:2px;"></i>
                                        {{(prop.value | date : 'MMM d, y, h:mm a')}}
                                    </span>
                                    </ng-container>
                                    <ng-template #standardDateDisplay>
                                        {{(prop.value | date : 'MMM d, y, h:mm a')}}
                                    </ng-template>
                                </ng-container>
                                <ng-container *ngSwitchCase="propertyType.FILESIZE">
                                    {{prop.value | docFileSize}}
                                </ng-container>
                            </ng-container>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <!-- Metrics -->
            <div class="ui bottom attached tab" data-tab="metrics">
                <folio-loader *ngIf="!documentMetrics"></folio-loader>
                <table class="ui very basic unstackable table" *ngIf="!!documentMetrics">
                    <tbody>
                    <tr>
                        <th scope="row"><h5>Views</h5></th>
                        <td class="metric">{{documentMetrics.views}}</td>
                        <td></td>
                    </tr>
                    <tr *ngIf="sharingEnabled$ | async">
                        <td class="has-child-table" colspan="3">
                            <table class="ui very basic unstackable child table">
                                <tbody>
                                <tr>
                                    <th scope="row">Internal views</th>
                                    <td class="metric">{{documentMetrics.internalViews}}</td>
                                    <td class="has-tooltip">
                                        <div class="tooltip" [attr.data-content]="'The number of times a Pepper Folio user viewed this content in the app. Shared links are not included in this count.'">
                                            <i class="info icon circle teal"></i>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Views of shared links</th>
                                    <td class="metric">{{documentMetrics.sharedLinkViews}}</td>
                                    <td class="has-tooltip">
                                        <div class="tooltip" [attr.data-content]="'The number of times someone viewed a shared link for this content. Both Pepper Folio users and external users are included in this count.'">
                                            <i class="info icon circle teal"></i>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row"><h5>Downloads</h5></th>
                        <td class="metric">{{documentMetrics.downloads}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row"><h5>Favorites</h5></th>
                        <td class="metric">{{documentMetrics.favorites}}</td>
                        <td></td>
                    </tr>
                    <tr *ngIf="sharingEnabled$ | async">
                        <th scope="row"><h5>Shares</h5></th>
                        <td class="metric">{{documentMetrics.shares}}</td>
                        <td class="has-tooltip">
                            <div class="tooltip" [attr.data-content]="'The number of shared links that have been generated for this content.'">
                                <i class="info icon circle teal"></i>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="link actions">
      <a (click)="handleCloseClick()">Close</a>
    </div>
</ng-container>
