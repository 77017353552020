<ng-container *ngIf="active">
  <div class="header">
    {{formType === 'new' ? 'New collection' : 'Edit collection'}}
  </div>
  <div class="content">
    <form class="ui form" [formGroup]="form">
      <flow-text-input [label]="'Name'" [formControlName]="'name'" [maxLength]="100">
      </flow-text-input>

      <flow-text-input [label]="'Description'" [formControlName]="'description'" [area]="true" [maxLength]="350">
      </flow-text-input>

      <flow-select-input [label]="'Icon'" [placeholder]="'Select icon'" [formControlName]="'icon'">
      </flow-select-input>

      <flow-select-input [label]="'Color'" [placeholder]="'Select color'" [formControlName]="'color'">
      </flow-select-input>

      <flow-multi-select-input *ngIf="visibilityConfig?.enabled"
                               [label]="visibilityConfig?.label || 'Visibility'"
                               [tooltip]="visibilityConfig?.helpText"
                               [placeholder]="'Select visibility'"
                               [formControlName]="'forUserGroups'">
      </flow-multi-select-input>

      <flow-checkbox-input [label]="'Promote on Home'" [formControlName]="'promote'">
      </flow-checkbox-input>
    </form>
  </div>
  <div class="actions">
    <div class="ui basic primary deny button">
      Cancel
    </div>
    <div class="ui teal submit button" (click)="handleSubmission()"
         [ngClass]="{'loading': requestLoader}">
      {{formType === 'new' ? 'Create' : 'Save'}}
    </div>
  </div>
</ng-container>
