import { Component, OnInit } from '@angular/core';
import { filter, first } from 'rxjs';
import { CollectionFolderItem, CollectionItem } from '@core/models';
import { updateCollection } from '@app/ngrx/collection/collection.actions';
import { getCollectionsState } from '@app/ngrx/collection/collection.reducer';
import { Update } from '@ngrx/entity';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DocUtil } from '@core/utils/doc.util';
import { FolioModalComponent } from '@shared/folio-modals/folio-modal/folio-modal.component';
import { ofType } from '@ngrx/effects';
import { FolioActions } from '@app/ngrx/actions';
import { FolioModalType } from '@core/models/modals.model';
import { select } from '@ngrx/store';
import { withLatestFrom } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector:    'folio-remove-from-collection-modal',
  templateUrl: './remove-from-collection-modal.component.html'
})
export class RemoveFromCollectionModalComponent extends FolioModalComponent implements OnInit {

  public documentIds: string[];
  public requestLoader = false;
  public collectionId: string;
  public selectedCollection: CollectionItem;
  public documentName: string;

  ngOnInit() {
    this.actions$.pipe(
      ofType(FolioActions.toggleModal),
      filter(payload => payload.modalType === FolioModalType.REMOVE_FROM_COLLECTION),
      withLatestFrom(this.store.pipe(select(getCollectionsState)))
    ).subscribe(([{show = true, data}, collectionState]) => {
      this.documentIds = data.documents.map(doc => DocUtil.getId(doc));
      if (this.documentIds.length == 1) {
        this.documentName = DocUtil.getDisplayName(data.documents[0]);
      }

      this.collectionId = data.collectionId;
      this.selectedCollection = collectionState.entities[this.collectionId];
      this.toggle(show);
    });
  }

  public handleConfirmClick() {
    this.requestLoader = true;
    const newDocumentIds = this.selectedCollection.documentIds.filter(id => !this.documentIds.includes(id));
    const updatedCollection = {...this.selectedCollection, documentIds: newDocumentIds};

    this.collectionsService.updateCollection(updatedCollection)
      .pipe(first())
      .subscribe((collection: CollectionItem) => {
        const updatedCollection: Update<CollectionFolderItem> = {
          id:      collection.id,
          changes: collection
        };
        this.store.dispatch(updateCollection({ collection: updatedCollection }));
        this.requestLoader = false;
        this.hide();
      });
  }
}
