import { ld } from '..';
import { CollectionsSortOrder } from '@core/models';


export function selectAll(list: any, activeProp: string) {
  ld.forEach(list, (item: any) => {
    item[activeProp] = true;
  });
}

export function deselectAll(list: any, activeProp: string) {
  ld.forEach(list, (item: any) => {
    item[activeProp] = false;
  });
}

/**
 * A helper function to sort Collections based on the parameters passed in.
 *
 * @param collections The collection to sort
 * @param caseInsensitiveSort A boolean value representing whether to have a case-insensitive sort
 * @param sortByName The field to sort on
 * @param order The order of the sort
 */
export function sortCollection(collections: any[], caseInsensitiveSort: boolean, sortByName: string, order: CollectionsSortOrder): any[] {
  const lowercaseIteratee = collection => collection.name.toLowerCase();
  return caseInsensitiveSort ? ld.orderBy(collections, [lowercaseIteratee, sortByName], [order, order]) : ld.orderBy(collections, [sortByName], [order, order]);
}
