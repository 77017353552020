<div class="field" [ngClass]="{'required': isRequired, 'error' : !isValid && isTouched}">
  <label>{{label}}</label>
  <div class="ui selection dropdown search icon">
    <input type="hidden" name="collection">
    <i class="dropdown icon"></i>
    <div class="default text">{{placeholder || 'Select'}}</div>
    <div class="menu">
      <div class="item" *ngFor="let option of options" [attr.data-value]="option.id">
        <i class="icon {{option.id}} {{option.icon}}"></i>
        <span>{{option.name}}</span>
      </div>
    </div>
  </div>
</div>
