import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { FolioState } from '../../ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { UrlUtil } from '../utils/url.util';
import { ErrorService } from './error.service';
import { Favorites, FavoritesRequest } from '../models';
import { selectUserId } from '../../ngrx/selectors';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  private readonly BASE_URL: string = UrlUtil.folioServerUrl('/api/v1/favorites');
  private readonly ADD_URL: string = `${this.BASE_URL}/add`;
  private readonly REMOVE_URL: string = `${this.BASE_URL}/remove`;

  constructor(private http: HttpClient,
              private errorService: ErrorService,
              private store: Store<FolioState>) {
  }

  getFavorites(): Observable<Favorites> {
    return this.http.get(this.BASE_URL).pipe(
      map(favorites => <Favorites> favorites),
      catchError(this.errorService.logModalAndContinue<Favorites>())
    );
  }

  addFavorites(documentIds: string[]): Observable<Favorites> {
    return this.toggleFavorite(documentIds, this.ADD_URL);
  }

  removeFavorites(documentIds: string[]): Observable<Favorites> {
    return this.toggleFavorite(documentIds, this.REMOVE_URL);
  }

  private toggleFavorite(documentIds: string[], url: string): Observable<Favorites> {
    return this.store.pipe(select(selectUserId)).pipe(
      take(1),
      switchMap(userId => {
        const favoritesRequest: FavoritesRequest = { userId, documentIds };
        return this.http.put(url, favoritesRequest).pipe(
          map(favorites => <Favorites> favorites),
          catchError(this.errorService.logModalAndContinue<Favorites>()));
      })
    );
  }
}
