import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as CollectionActions from './collection.actions';
import { FetchProgress } from '../store';
import { ld } from '@core';
import { CollectionItem, CollectionsState } from '@core/models';

const adapter: EntityAdapter<CollectionItem> = createEntityAdapter<CollectionItem>({
  sortComparer: false,
});

export const initialState: CollectionsState = adapter.getInitialState({
  error:    null,
  status:   FetchProgress.LOADING,
  parentId: 'root'
});

export const reducer = createReducer(
  initialState,

  on(CollectionActions.loadCollectionsSuccess, (state: CollectionsState, { items, layout }) => {
    return adapter.setAll(items, { ...state, layout, status: FetchProgress.LOADED });
  }),

  on(CollectionActions.loadCollectionsFailed, (state: CollectionsState, { error }) => {
    return { ...state, status: FetchProgress.ERROR, error };
  }),

  on(CollectionActions.updateCollection, (state, { collection }) => {
    return adapter.updateOne(collection, state);
  }),

  on(CollectionActions.updateCollections, (state, { updates }) => {
    return adapter.updateMany(updates, state);
  }),

  on(CollectionActions.updateCollectionsView, (state: CollectionsState, { layout, sortOrder, parentId }) => {
    return { ...state, layout, sortOrder, parentId };
  }),

  on(CollectionActions.addCollection, (state, { collection }) => {
    return adapter.addOne(collection, state);
  }),

  on(CollectionActions.removeCollection, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),

  on(CollectionActions.setVisibilityOptions, (state, { options }) => {
    return { ...state, visibilityOptions: options };
  }),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getCollectionsState = createFeatureSelector<CollectionsState>('collections');
export const getCollectionsList = createSelector(getCollectionsState, (state: CollectionsState) => {
  return ld.values(state.entities);
});
export const getVisibilityOptions = createSelector(getCollectionsState, (state: CollectionsState) => {
  return state.visibilityOptions
});
