import { Const } from './const';
import isEqual from 'lodash.isequal';
import { UrlUtil } from './url.util';

/**
 * Basic data utility functions
 */
export class DataUtil {

  static isBlank(s: string): boolean {
    return !s || /^\s*$/.test(s);
  }

  static isNotBlank(s: string): boolean {
    return !DataUtil.isBlank(s);
  }

  static isArray(v: any): boolean {
    return !!v && Object.prototype.toString.call(v) === '[object Array]';
  }

  static isDateString(arg) {
    // match that it begins with 'YYYY-DD-MMTHH:mm:ss'
    return typeof arg === 'string' && arg.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/);
  }

  static isDateWithin(date: any, days: number): boolean {
    if (!date) {
      return false;
    }

    date = new Date(date);
    if (!(date instanceof Date)) {
      return false;
    }

    const now    = new Date();
    const period = days * Const.DAY_IN_MILLIS;
    return date.getTime() < (now.getTime() + period);
  }

  static deepEquals(a: any, b: any) {
    return isEqual(a, b);
  }

  static isEmpty(map: any = {}) {
    return !Object.keys(map).length;
  }

  static notEmpty(map: any = {}) {
    return !DataUtil.isEmpty(map);
  }

  // ----------------------------------------------------

  static indexBy<T>(f: (x: T) => string, items: T[]): { [s: string]: T } {
    const lookup = {};
    (items || []).forEach(item => {
      lookup[f(item)] = item;
    });
    return lookup;
  }

  // ----------------------------------------------------

  static removeNulls(obj: any): any {
    if (!obj) {
      return obj;
    }

    for (const prop in obj) {
      if (obj.hasOwnProperty(prop) && !obj[prop]) {
        delete obj[prop];
      }
    }
    return obj;
  }

  // ----------------------------------------------------

  static clone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  static flatten(xs: any[]): any[] {
    const isArrayLike = x => Object.prototype.toString.call(x) === '[object Array]';
    return [].concat.apply([], (xs || []).map(x => {
      if (isArrayLike(x)) {
        return DataUtil.flatten(x);
      } else {
        return [x];
      }
    }));
  }

  static union<T>(x1: Set<T>, x2: Set<T>): Set<T> {
    const result = new Set();
    x1.forEach(x => result.add(x));
    x2.forEach(x => result.add(x));
    // @ts-ignore - no clue what tslint doesn't like here
    return result;
  }

  // ----------------------------------------------------

  static getHumanReadableFileSize(bytes: number): string {
    const thresh = 1000;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u       = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  }

  // ----------------------------------------------------

  static uuid(): string {
    const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  static getJwtClaims(token: string): { [s: string]: any } {
    const claimSection = token.match(/(.+)\.(.+)\.(.+)/)[2];
    // atob is base 64 decoding the middle section of jwt.
    return UrlUtil.decode(claimSection);
  }

}
