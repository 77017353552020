export const COLLECTION_ICONS: {name: string; id: string}[] = [
  { name: 'Collections (default)', 'id': 'folder' },
  { name: 'Bell', id: 'bell' },
  { name: 'Bolt', id: 'bolt' },
  { name: 'Bullhorn', id: 'bullhorn' },
  { name: 'Capsules', id: 'capsules' },
  { name: 'Certificate', id: 'certificate' },
  { name: 'Clipboard', id: 'clipboard' },
  { name: 'Clone', id: 'clone' },
  { name: 'Comment dollar', id: 'comment dollar' },
  { name: 'File alternate outline', id: 'file alternate outline' },
  { name: 'Flag', id: 'flag' },
  { name: 'Globe', id: 'globe' },
  { name: 'Heartbeat', id: 'heartbeat' },
  { name: 'Hospital', id: 'hospital' },
  { name: 'Image outline', id: 'image outline' },
  { name: 'Lightbulb', id: 'lightbulb' },
  { name: 'Medkit', id: 'medkit' },
  { name: 'Microscope', id: 'microscope' },
  { name: 'Pills', id: 'pills' },
  { name: 'Prescription bottle', id: 'prescription bottle' },
  { name: 'Seedling', id: 'seedling' },
  { name: 'Stethoscope', id: 'stethoscope' },
  { name: 'Syringe', id: 'syringe' },
  { name: 'Vial', id: 'vial' },
  { name: 'User MD', id: 'user mD' },
  { name: 'Users', id: 'users' },
];

export const COLLECTION_COLORS: {name: string; id: string}[] = [
  { name: 'Gray (default)', 'id': 'grey' },
  // { name: 'Berry', id: '#9b3366' },
  { name: 'Black', id: 'black' },
  { name: 'Blue', id: 'blue' },
  // { name: 'Blush', id: '#f979ac' },
  { name: 'Brown', id: 'brown' },
  { name: 'Green', id: 'green' },
  // { name: 'Lilac', id: '#b990f1' },
  { name: 'Olive', id: 'olive' },
  { name: 'Orange', id: 'orange' },
  { name: 'Pink', id: 'pink' },
  { name: 'Purple', id: 'purple' },
  { name: 'Red', id: 'red' },
  // { name: 'Slate', id: '#8ba6b6' },
  // { name: 'Terracotta', id: '#d75656' },
  { name: 'Violet', id: 'violet' },
  { name: 'Yellow', id: 'yellow' },
];
