import { ToolingConfiguration } from './tooling.configuration';
import { SentryUtil } from './sentry.util';
import { UrlUtil } from './url.util';

export class ToolingUtil {

  static initNewRelic(): void {
    if (UrlUtil.isLocal()) {
      console.info('Not configuring NewRelic for local development.');
      return;
    }

    const hostEnv = UrlUtil.getHostingEnvironment();
    if (hostEnv) {
      ToolingConfiguration.NEWRELIC_INITS[hostEnv]();
    } else {
      console.error('Unable to configure NewRelic for host environment.');
    }
  }

  static initSentry(): void {
    const hostEnv      = UrlUtil.getHostingEnvironment();
    const sentryConfig = ToolingConfiguration.SENTRY[hostEnv];
    if (!!hostEnv && !!sentryConfig?.dsn) {
      SentryUtil.initialize(sentryConfig);
    } else {
      console.error('Unable to configure Sentry for host environment.');
    }
  }
}
