import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { UrlUtil } from '../utils/url.util';
import { catchError, map } from 'rxjs/operators';
import { AuditData, AuditRequest } from '../models/audit.model';
import { AuditUtil } from '../utils/audit.util';
import { FolioDocument } from '../models';
import { Store } from '@ngrx/store';
import { FolioState } from '@app/ngrx/store';
import { ConfigService } from './config.service';
import { SentryUtil } from '../utils/sentry.util';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private store: Store<FolioState>,
              private http: HttpClient,
              private configService: ConfigService,
              private errorService: ErrorService) {
  }

  createAudit(event: string,
    doc: FolioDocument = null,
    data: AuditData = {},
    eventTimestamp: string | Date = new Date()): void {
    if (!event) {
      SentryUtil.sendSentryMessage(new Error('Trying to create an audit without a supplied event name.'));
      return;
    }

    const url                        = UrlUtil.folioServerUrl('/api/v1/audits');
    const auditRequest: AuditRequest = AuditUtil.getAuditRequest(event, doc, data, eventTimestamp);

    this.http.post(url, [auditRequest]).pipe(
      map(response => response),
      catchError(this.errorService.logAndContinue<any>())
    ).subscribe(_ => {
      // empty subscription
    });
  }
}
