import { Injectable } from '@angular/core';
import {
  CollectionFolderItem, CollectionItem,
  CollectionsState,
  LoadCollectionsAction, UpdateCollectionsViewAction
} from '@core/models/collections.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { catchError, delay, first, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { ld } from '@core';
import { CollectionsService } from '@core/services/collections.service';
import { FolioState } from '../store';
import { addCollection, loadCollections, loadCollectionsFailed, loadCollectionsSuccess, updateCollection } from './collection.actions';
import { getCollectionsState } from './collection.reducer';

const HIGHLIGHT_NEW_ITEM_IN_LIST = 5000;

@Injectable()
export class CollectionEffects {

  constructor(
    private actions$: Actions,
    private store: Store<FolioState>,
    private collectionsService: CollectionsService,
  ) { }

  loadCollections$ = createEffect(() => {
    let loadAction: LoadCollectionsAction;
    return this.actions$.pipe(
      ofType(loadCollections),
      tap((action: LoadCollectionsAction) => {
        loadAction = action;
      }),
      switchMap(() => {
        return this.store.select(getCollectionsState).pipe(first());
      }),
      switchMap((collectionsState: CollectionsState) => {
        const collectionItems$ = !loadAction.needRefresh && collectionsState.ids.length
          ? of(ld.values(collectionsState.entities))
          : this.collectionsService.getCollections();

        return forkJoin([
          this.collectionsService.getSortAndLayoutOptions().pipe(first()),
          collectionItems$,
        ]);
      }),
      map(([sortViewOptions, items]: [UpdateCollectionsViewAction, CollectionItem[]]) => {
        const { layout, sortOrder, parentId } = sortViewOptions;
        return loadCollectionsSuccess({ items, layout, sortOrder, parentId });
      }),
      catchError(error => {
        return of(loadCollectionsFailed({ error }));
      })
    );
  });

  createCollection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addCollection),
      delay(HIGHLIGHT_NEW_ITEM_IN_LIST),
      map((action: { collection: CollectionItem }) => {
        const updatedCollection: Update<CollectionFolderItem> = {
          id:      action.collection.id,
          changes: { justCreated: false }
        };
        return updateCollection({ collection: updatedCollection });
      })
    );
  });
}
