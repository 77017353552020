<div class="field" [ngClass]="{'required': isRequired, 'error' : !isValid && isTouched}">
  <label>
    {{label}}
    <span *ngIf="!!tooltip" class="tooltip" [attr.data-content]="tooltip">
      <i class="help icon circle teal"></i>
    </span>
  </label>
  <select name="skills" multiple="" class="ui fluid dropdown">
    <option *ngFor="let option of options" value="{{option.id}}">{{option.name}}</option>
  </select>
</div>
