import { ld } from '@core';
import { CollectionFolderItem, CollectionItemType } from '@core/models';

export interface BreadcrumbItem {
    id: string|null;
    name: string;
}

export function prepareBreadcrumbList(collections: CollectionFolderItem[], currentId: string) {
  const result: CollectionFolderItem[] = [];
  const parentsMap                     = new Map();

  ld.forEach(collections, (item: CollectionFolderItem) => {
    parentsMap.set(item.id, item);
  });

  function findParent(id: string) {
    const current: CollectionFolderItem = parentsMap.get(id);
    if (current) {
      result.push(current);
    }
    if (ld.get(current, 'parentId')) {
      findParent(current.parentId);
    }
  }

  if (currentId) {
    findParent(currentId);
  }
  result.push({ id: null, name: 'Collections', parentId: null, icon: '', color: '', type: CollectionItemType.FOLDER });

  return result.reverse();

}

export function getAllChildren(collections: CollectionFolderItem[], currentId: string): string[] {
  let result: CollectionFolderItem[] = [];
  const parentsMap                   = new Map();

  ld.forEach(collections, (item: CollectionFolderItem) => {
    parentsMap.set(item.id, item);
  });

  function findСhildren(children) {
    result = result.concat(children);
    for (const item of children) {
      if (parentsMap.get(item.id)) {
        findСhildren(getChildrenList(collections, item.id));
      }
    }
  }

  function getChildrenList(collections, id): any {
    return ld.filter(collections, (item) => {
      return item.parentId === id;
    });
  }

  if (currentId) {
    findСhildren(getChildrenList(collections, currentId));
  }

  return ld.map(result, 'id');

}




