export class FomanticFormUtil {

  static emailIsValid(email): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  /**
     * Returns a jQuery object of the given HTML element
     * @param elem
     */
  static $elem(elem: HTMLElement | JQuery): JQuery {
    // @ts-ignore
    return (elem instanceof jQuery) ? elem : $(elem);
  }

  /**
     * Returns response of Semantic's 'is valid' function
     * @param formElem
     */
  static isValid(formElem: HTMLElement | JQuery): boolean {
    return this.$elem(formElem).form('is valid');
  }

  /**
     * Calls Semantic's 'validate form' function
     * @param formElem
     */
  static validate(formElem: HTMLElement | JQuery): void {
    this.$elem(formElem).form('validate form');
  }

  /**
     * Validates the given form element and returns true if valid; false otherwise
     * (Note the workaround for Fomantic bug where 'is valid' removes error classes - https://github.com/fomantic/Fomantic-UI/issues/923)
     * @param formElem
     */
  static validateAndReturn(formElem: HTMLElement | JQuery): boolean {
    const v = this.isValid(formElem);
    if (!v) {
      // we're only calling validate to ensure error classes are properly applied
      // no reason to do that if we know the form is valid
      this.validate(formElem);
    }
    return v;
  }
}
