import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ERROR_COMPONENT_PATHS, ErrorModalData } from '../models/error.model';
import { MessagingService } from './messaging.service';
import { SentryUtil } from '../utils/sentry.util';
import { DocUtil } from '../utils/doc.util';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private router: Router, private messagingService: MessagingService) {
  }

  /**
   * Log to Sentry (as message) and return an observable to allow app to continue
   */
  logAndContinue<T>(message: string = null, result: T = null) {
    return (error: any): Observable<T> => {
      SentryUtil.sendSentryMessage(error);
      return of(result as T);
    };
  }

  /**
   * Log to Sentry (as exception), show a modal to the user, and return an observable to allow app to continue
   */
  logModalAndContinue<T>(message: string = null, result: T = null) {
    return (error: any): Observable<T> => {
      SentryUtil.sendSentryException(error);
      this.messagingService.emitDataForErrorModal({ message });
      return of(result as T);
    };
  }

  /**
   * Log to Sentry (as message) and route to the given status page
   */
  logAndRouteToStatus<T>(status: number = null, message: string = null, result: T = null) {
    return (error: any): Observable<T> => {
      SentryUtil.sendSentryMessage(error);
      this.routeToStatus(status || error.status);
      return of(result as T);
    };
  }

  /**
   * Show the share error modal
   */
  showShareErrorModal<T>(status: number = null, message: string = null, result: T = null) {
    return (error: any): Observable<T> => {
      const data: ErrorModalData = {
        title:     DocUtil.MESSAGES.CANT_REACTIVATE_SHARE,
        message:   message || DocUtil.MESSAGES.NO_LONGER_SHAREABLE,
        noRefresh: true
      };
      this.messagingService.emitDataForErrorModal(data);
      return of(result as T);
    };
  }

  doNothing<T>() {
    return (error: any): Observable<T> => {
      throw error;
    };
  }

  /**
   * Log to Sentry (as message) and route to the custom public document 404 status page
   */
  logAndRouteToPublicDocument404<T>(status: number = null, message: string = null, result: T = null) {
    return (response: any): Observable<T> => {
      SentryUtil.sendSentryMessage(response);
      const path = ERROR_COMPONENT_PATHS['shared-404'];
      this.router.navigate(path, { skipLocationChange: true, state: { logo: response?.error?.data?.customerLogo } }).then();
      return of(result as T);
    };
  }

  private routeToStatus(status: number): void {
    if (ERROR_COMPONENT_PATHS[status]) {
      this.routeToErrorPage(ERROR_COMPONENT_PATHS[status]);
    } else {
      this.routeToErrorPage(ERROR_COMPONENT_PATHS[404]);
    }
  }

  routeToErrorPage(path: string[]): void {
    this.router.navigate(path, { skipLocationChange: true }).then();
  }
}
