import { Pipe, PipeTransform } from '@angular/core';
import { DataUtil } from '@core/utils/data.util';

@Pipe({
  name: 'withinDays'
})
export class WithinDaysPipe implements PipeTransform {

  transform(date: any, days = 30): boolean {
    return DataUtil.isDateWithin(date, days);
  }

}
