import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import {
  CollectionItem,
  LoadCollectionFailedAction,
  LoadCollectionsAction,
  LoadCollectionsSuccessAction,
  StaticListOption,
  UpdateCollectionsViewAction
} from '@core/models';

export const loadCollections = createAction(
  '[Collection/API] Load Collections',
  props<LoadCollectionsAction>()
);

export const loadCollectionsSuccess = createAction(
  '[Collection/API] Load Collections success',
  props<LoadCollectionsSuccessAction>()
);

export const loadCollectionsFailed = createAction(
  '[Collection/API] Load Collections failed',
  props<LoadCollectionFailedAction>()
);

export const updateCollection = createAction(
  '[Collection/API] Update Collection',
  props<{ collection: Update<CollectionItem> }>()
);

export const updateCollections = createAction(
  '[Collection/API] Update Collections',
  props<{ updates: Update<CollectionItem>[] }>()
);

export const updateCollectionsView = createAction(
  '[Collection/API] Update Collections view',
  props<UpdateCollectionsViewAction>()
);

export const addCollection = createAction(
  '[Collection/API] Add collection',
  props<{ collection: CollectionItem }>()
);

export const removeCollection = createAction(
  '[Collection/API] Remove collection',
  props<{ id: string }>()
);

export const setVisibilityOptions = createAction(
  '[Collection/API] Set Visibility Options',
  props<{ options: StaticListOption[] }>()
);

